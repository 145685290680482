import React from 'react';

export const DuplicateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    viewBox="0 0 19 19"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M5,4.00000419 L5,3.00000419 L5,3.00000419 C5,2.44771944 5.44771525,2.00000419 6,2.00000419 L6,2.00000419 L14.9999934,2.00000419 L14.9999934,2.00000419 C15.5522781,2.00000419 15.9999934,2.44771944 15.9999934,3.00000419 L15.9999934,13.9998629 C15.9999934,14.5521477 15.5522781,14.9998629 14.9999934,14.9998629 L13.9999934,14.9998629 L13.9999934,15.9998629 C13.9999934,16.5521477 13.5522781,16.9998629 12.9999934,16.9998629 L9.59999603,16.9998629 L4,16.9998629 C3.44771525,16.9998629 3,16.5521477 3,15.9998629 L3,5.00000419 C3,4.44771944 3.44771525,4.00000419 4,4.00000419 L4,4.00000419 L5,4.00000419 Z M5,5.00000419 L4,5.00000419 L4,15.9998847 L9.39999675,15.9998847 L12.9999946,15.9998847 L12.9999946,14.9998629 L11.599996,14.9998629 L6,14.9998629 C5.44771525,14.9998629 5,14.5521477 5,13.9998629 L5,5.00000419 Z M6,3.00000419 L6,13.9998847 L11.3999967,13.9998847 L14.9999946,13.9998847 L14.9999946,3.00000419 L6,3.00000419 Z" />
  </svg>
);
