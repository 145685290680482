import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { resolveId } from '@wix/communities-blog-client-common';
import RatingsDisplay, {
  RatingsDisplayLayout,
} from '@app/external/common/components/ratings-display';
import { useSelector } from '@app/external/common/components/runtime-context';
import { getIsMobile } from '@app/external/common/store/basic-params/basic-params-selectors';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '@app/external/common/store/post-ratings/post-ratings-selector';
import { type NormalizedPost } from '@app/external/common/types';

import styles from './post-page-post-header-ratings.scss';

type Props = {
  post: NormalizedPost;
  onClick?: () => void;
};

const PostPagePostHeaderRatings = (props: Props) => {
  const { isSSR } = useEnvironment();
  const isMobile = useSelector(getIsMobile);

  const postAverageRating = useSelector((state) =>
    getPostAverageRating(state, resolveId(props.post)),
  );
  const postTotalRatings = useSelector((state) =>
    getPostTotalRatings(state, resolveId(props.post)),
  );

  const isPostRatingLoading =
    typeof postAverageRating === 'undefined' && typeof postTotalRatings === 'undefined';
  /**
   * We don't have ratings information in SSR and sometimes initial CSR render will have ratings fetched,
   * which will result in hydration error. In that case we render ratings in different element for CSR
   */
  const renodeRatings = !isSSR && !isPostRatingLoading;

  return (
    <button className={styles.root} onClick={props.onClick}>
      <RatingsDisplay
        rating={postAverageRating}
        count={postTotalRatings}
        isLoading={isPostRatingLoading}
        useTransitions={renodeRatings || isPostRatingLoading}
        layout={isMobile ? RatingsDisplayLayout.bracket_separated : RatingsDisplayLayout.default}
      />
    </button>
  );
};

export default PostPagePostHeaderRatings;
