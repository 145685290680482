import React, { type PropsWithChildren, useContext } from 'react';
import {
  type UseFeedMetadataSettings,
  useFeedMetadataSettings,
} from '@app/external/common/hooks/use-feed-metadata-settings';
import { usePostPageShareProviders } from '@app/external/common/hooks/use-post-page-share-providers';

type OverridesFromFeed = Pick<
  UseFeedMetadataSettings,
  | 'showAuthorName'
  | 'showAuthorPicture'
  | 'showCategoryLabels'
  | 'showCommentCount'
  | 'showLikeCount'
  | 'showMoreOptionsMenu'
  | 'showPostRating'
  | 'showPostUpdatedDate'
  | 'showPublishDate'
  | 'showReadingTime'
  | 'showViewCount'
> & {
  isInPostPage: boolean;

  showShareSection: boolean;
  showShareOnFacebook: boolean;
  showShareOnX: boolean;
  showShareOnLinkedIn: boolean;
  showShareOnLink: boolean;
  showShareOnPrint: boolean;

  showStatsSection: boolean;
};

// Default values of `true` assumes that Display is handled by CSS "display" property by Post page
const FullPostLayoutOverridesContext = React.createContext<OverridesFromFeed>({
  isInPostPage: true,

  showAuthorName: true,
  showAuthorPicture: true,
  showCategoryLabels: true,
  showCommentCount: true,
  showLikeCount: true,
  showMoreOptionsMenu: true,
  showPostRating: true,
  showPostUpdatedDate: true,
  showPublishDate: true,
  showReadingTime: true,
  showViewCount: true,

  showShareSection: true,
  showShareOnFacebook: true,
  showShareOnLink: true,
  showShareOnLinkedIn: true,
  showShareOnPrint: true,
  showShareOnX: true,

  showStatsSection: true,
});

export const useFullPostLayoutOverrides = () => useContext(FullPostLayoutOverridesContext);

export const FullPostLayoutOverridesProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const {
    showCommentCount,
    showViewCount,
    showLikeCount,
    showAuthorName,
    showAuthorPicture,
    showPublishDate,
    showReadingTime,
    showMoreOptionsMenu,
    showPostUpdatedDate,
    showPostRating,
    showCategoryLabels,
  } = useFeedMetadataSettings();
  const share = usePostPageShareProviders();

  const showShareOnFacebook = share.providers.includes('facebook');
  const showShareOnLink = share.providers.includes('link');
  const showShareOnLinkedIn = share.providers.includes('linked-in');
  const showShareOnPrint = share.isPrintEnabled;
  const showShareOnX = share.providers.includes('twitter');

  return (
    <FullPostLayoutOverridesContext.Provider
      value={{
        isInPostPage: false,

        showAuthorName,
        showAuthorPicture,
        showPublishDate,
        showReadingTime,
        showMoreOptionsMenu,
        showPostUpdatedDate,
        showPostRating,
        showCategoryLabels,

        showShareSection:
          showShareOnFacebook ||
          showShareOnLink ||
          showShareOnLinkedIn ||
          showShareOnPrint ||
          showShareOnX,
        showShareOnFacebook,
        showShareOnLink,
        showShareOnLinkedIn,
        showShareOnPrint,
        showShareOnX,

        showStatsSection: showCommentCount || showViewCount || showLikeCount,
        showCommentCount,
        showViewCount,
        showLikeCount,
      }}
    >
      {children}
    </FullPostLayoutOverridesContext.Provider>
  );
};
