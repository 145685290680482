import React from 'react';

const ClearIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    style={{ fillRule: 'evenodd' }}
    {...props}
  >
    <path
      d="M3144.99,617.882l-1.11,1.109-3.88-3.882-3.88,3.882-1.11-1.109,3.88-3.882-3.88-3.882,1.11-1.109,3.88,3.882,3.88-3.882,1.11,1.109L3141.11,614Z"
      transform="translate(-3135 -609)"
    />
  </svg>
);

export default ClearIcon;
