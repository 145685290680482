import { useEffect, useRef } from 'react';
import { useActions, useSelector } from '../components/runtime-context';
import { getCurrentMatch } from '../router/router-selectors';
import { getPostBySlug } from '../selectors/post-selectors';
import { isInPostPage } from '../services/detect-route';
import { resolvePostSlug } from '../services/slug';

export const useBlogNotifications = () => {
  const actions = useActions();

  useEffect(() => {
    actions.emitBlogOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const usePostPushNotifications = () => {
  const currentMatch = useSelector(getCurrentMatch);

  const currentPost = useSelector((state) => {
    if (isInPostPage(currentMatch.route)) {
      const postSlug = resolvePostSlug(currentMatch.params);
      return getPostBySlug(state, postSlug);
    }
  });

  const currentPathname = currentMatch.pathname!;

  const postRef = useRef<{ post: any; pathname: string } | null>(null);
  const actions = useActions();

  useEffect(() => {
    if (postRef.current && currentPathname !== postRef.current.pathname) {
      actions.emitClosePost(postRef.current.post);
      postRef.current = null;
    }

    if (!postRef.current && currentPost) {
      postRef.current = { post: currentPost, pathname: currentPathname };
      actions.emitOpenPost(currentPost);
    }
  }, [currentPathname, currentPost, actions]);
};
