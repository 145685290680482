import React from 'react';

export const PlayIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    data-hook="play-icon"
    {...props}
  >
    <circle style={{ fill: '#1a1a1a' }} cx="15" cy="15" r="15" />
    <polygon style={{ fill: '#fff' }} points="11 9 11 21 21 14.88 11 9" />
  </svg>
);
