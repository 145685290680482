/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classNames from 'classnames';

import { getActiveLink, getFeedAndCategoryLinks } from '../../selectors/category-links-selectors';
import Link from '../link/internal-link';
import { useSelector } from '../runtime-context';
import styles from './seo-select.scss';

const SeoSelect: React.FC = () => {
  const links = useSelector(getFeedAndCategoryLinks);
  const activeLink = useSelector(getActiveLink);

  return (
    <nav className={classNames(styles.dropdown, 'blog-navigation-container-background-color')}>
      <ul id="toggle-seo-nav">
        {links.map(({ path, text }, i) => (
          <Link key={i} to={path}>
            {text}
          </Link>
        ))}
      </ul>
      <a href="#toggle-seo-nav" className={styles.button}>
        {activeLink.text}
      </a>
      <a href="#" className={classNames(styles.button, styles.close)}>
        Close
      </a>
    </nav>
  );
};

export default SeoSelect;
