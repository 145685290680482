import { getAllPostsLabelTranslation } from '@wix/communities-blog-client-common';
import { type AppState } from '../types';
import { getQueryLocale } from './locale-selectors';

export const getViewerAllPostsLabelTranslation = (state: AppState) =>
  getQueryLocale(state)
    ? getAllPostsLabelTranslation({ state, language: getQueryLocale(state) })
    : '';

export const getAllPostsFeedLabel = (state: AppState) =>
  state.tpaSettings.settings?.allPostsFeedLabel;
