import { useContext } from 'react';
import { PermissionContext } from '../context/permission-context';
import createPermissionHelpers from '../services/create-permission-helpers';

export default function usePermissions() {
  const permissions = useContext(PermissionContext);

  return {
    permissions,
    ...createPermissionHelpers(permissions),
  };
}
