import React, { useEffect, useReducer, useState } from 'react';
import classNames from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  getCategoryPath,
  getIsPostPageBundle,
  type NormalizedCategory,
} from '@wix/communities-blog-client-common';
import useAuth from '../../hooks/use-auth';
import { getCurrentMatchPathname, getRouteParams } from '../../router/router-selectors';
import { getLastUpdatedDate } from '../../selectors/app-settings-selectors';
import {
  getAllPostsFeedLabel,
  getViewerAllPostsLabelTranslation,
} from '../../selectors/viewer-all-post-label-translation-selectors';
import { getHeaderLinks, LATEST_POSTS } from '../../services/get-header-links';
import { getViewMode } from '../../store/basic-params/basic-params-selectors';
import { getCategoriesSlice } from '../../store/categories/categories-selectors';
import { getSearchInputWidth } from '../../store/search-input/search-input-selectors';
import InternalLink from '../link/internal-link';
import MoreCategoriesDropdown from '../more-categories-dropdown';
import { useSelector } from '../runtime-context';
import { calculateHeaderItems, LINK_PADDING } from './calculate-header-items';
import styles from './header-navigation.scss';

type ReducerState = {
  categories: NormalizedCategory[];
  visibleCategories: NormalizedCategory[];
  moreCategories: NormalizedCategory[];
  moreCategoriesMenuWidth: number | undefined;
};

type ReducerAction =
  | {
      type: 'updateVisibleCategories';
      payload: NormalizedCategory[];
    }
  | {
      type: 'setMoreCategories';
      payload: NormalizedCategory[];
    }
  | {
      type: 'setMoreCategoriesMenuWidth';
      payload: number | undefined;
    };

const reducer = (state: ReducerState, action: ReducerAction) => {
  if (action.type === 'updateVisibleCategories') {
    return {
      ...state,
      visibleCategories: action.payload,
    };
  }

  if (action.type === 'setMoreCategories') {
    return {
      ...state,
      moreCategories: action.payload,
    };
  }

  if (action.type === 'setMoreCategoriesMenuWidth') {
    return {
      ...state,
      moreCategoriesMenuWidth: action.payload,
    };
  }

  return state;
};

type Props = {
  className?: string;
};

const HeaderNavigation: React.FC<Props> = ({ className }) => {
  const categories = useSelector((state) => getCategoriesSlice(state, 30));

  const [state, dispatch] = useReducer(reducer, {
    categories,
    visibleCategories: [],
    moreCategories: [],
    moreCategoriesMenuWidth: undefined,
  });

  const [accessibilityAlert, setAccessibilityAlert] = useState<string>();

  const { page } = useSelector(getRouteParams) ?? {};
  const currentMatchPathname = useSelector(getCurrentMatchPathname);
  const currentPath = page
    ? currentMatchPathname.replace(`/page/${page}`, '')
    : currentMatchPathname;
  const { dimensions } = useEnvironment();
  const { t } = useTranslation();

  const auth = useAuth();

  const hostWidth = dimensions.width;
  const currentPathDecoded = decodeURIComponent(currentPath);
  const searchInputWidth = useSelector(getSearchInputWidth);
  const feedTranslatedTitle = useSelector(getViewerAllPostsLabelTranslation);
  const titleFromSettings = useSelector(getAllPostsFeedLabel);
  const viewMode = useSelector(getViewMode);
  const settingsUpdated = useSelector(getLastUpdatedDate);
  const categoryPath = useSelector(getCategoryPath);
  const isPostPageBundle = useSelector(getIsPostPageBundle);

  const topNavigationContainerRef = React.useRef<HTMLElement>(null);
  const listItemsRef = React.useRef<HTMLUListElement>(null);

  const [shouldRenderMoreButton, setShouldRenderMoreButton] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (state.moreCategories.length > 0) {
      return setShouldRenderMoreButton(true);
    }

    const topNavigationContainer = topNavigationContainerRef.current;

    const topNavigationContainerLiItems = Array.from(listItemsRef.current?.children || []);

    if (!topNavigationContainer || topNavigationContainerLiItems.length === 0) {
      return setShouldRenderMoreButton(false);
    }

    const rootWidth = topNavigationContainer?.getBoundingClientRect().width!;
    const renderedItemsWidth = Array.from(topNavigationContainerLiItems).reduce(
      (acc, link, index) =>
        acc + link.getBoundingClientRect().width + (index === 0 ? 0 : LINK_PADDING),
      0,
    );

    return setShouldRenderMoreButton(rootWidth < renderedItemsWidth);
  }, [state.moreCategories.length]);

  const showAccessibilityAlert = (alert: string) => {
    setAccessibilityAlert(alert);
  };

  useEffect(() => {
    dispatch({ type: 'updateVisibleCategories', payload: categories });
    dispatch({ type: 'setMoreCategories', payload: [] });
  }, [settingsUpdated, viewMode, hostWidth, auth.isAuthenticated, searchInputWidth, categories]);

  useEffect(() => {
    const calculatedItems = calculateHeaderItems({
      categories,
      currentVisibleCategories: state.visibleCategories,
    });

    if (!calculatedItems) {
      return;
    }

    dispatch({
      type: 'updateVisibleCategories',
      payload: calculatedItems.visibleCategories,
    });
    dispatch({
      type: 'setMoreCategories',
      payload: calculatedItems.moreCategories,
    });
    dispatch({
      type: 'setMoreCategoriesMenuWidth',
      payload: calculatedItems.moreCategoriesMenuWidth,
    });
  }, [categories, state.visibleCategories]);

  const feedTitle = feedTranslatedTitle || titleFromSettings || '';

  const links = getHeaderLinks({
    categories: isClient ? state.visibleCategories : state.categories,
    feedTitle,
    categoryPath,
    withLinkToAllPosts: true,
  });

  return (
    <nav
      ref={topNavigationContainerRef}
      className={classNames(
        { [styles.overflowHidden]: !shouldRenderMoreButton },
        styles.topNavigation,
        'blog-header__navigation',
        'blog-navigation-container-font',
        className,
      )}
      aria-label={t('blog-mobile-header.title.blog')}
    >
      {accessibilityAlert ? (
        <div role="alert" className={styles.visuallyHidden}>
          {accessibilityAlert}
        </div>
      ) : null}
      <ul className={styles.container} ref={listItemsRef}>
        {links.map((link) => {
          const isActive =
            link.key === LATEST_POSTS && isPostPageBundle
              ? false
              : currentPathDecoded === link.path;
          const linkClassNames = classNames(
            'blog-navigation-container-color blog-navigation-container-font ',
            'blog-navigation-link-hover-color',
            {
              'blog-navigation-link-active-color': isActive,
            },
          );

          return (
            <li key={link.key} data-hook={`header-navigation__${link.path}`}>
              <InternalLink
                className={linkClassNames}
                to={link.path}
                addHoverClasses={false}
                isActive={isActive}
                onNavigation={() => showAccessibilityAlert(link.text)}
              >
                {link.text}
              </InternalLink>
            </li>
          );
        })}
        {shouldRenderMoreButton && (
          <li
            style={{
              position: 'relative',
            }}
            id="categories-more-button"
          >
            <MoreCategoriesDropdown
              currentPath={currentPath}
              items={getHeaderLinks({
                categories: state.moreCategories,
                feedTitle,
                categoryPath,
                withLinkToAllPosts: false,
              })}
              width={state.moreCategoriesMenuWidth}
              onNavigation={(link) => showAccessibilityAlert(link.text)}
            />
          </li>
        )}
      </ul>
    </nav>
  );
};

export default HeaderNavigation;
