import {
  getSocialSharingProviders,
  getIsCommentsEnabled,
} from '../selectors/app-settings-selectors';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';

const createPermissionFlags = (state) => ({
  isMemberAreaInstalled: getIsMemberAreaInstalled(state),
  isSocialSharingEnabled: Boolean(getSocialSharingProviders(state).length),
  isCommentsEnabled: getIsCommentsEnabled(state),
});

export default createPermissionFlags;
