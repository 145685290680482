import React, { Component } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import ensureAuth from '../../hoc/ensure-auth';

class ProtectedButton extends Component {
  static propTypes = {
    tagName: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    isAuthenticated: PropTypes.bool.isRequired,
    children: PropTypes.node,
  };

  render() {
    const { tagName: CategoryName = 'button', children, onClick, ...restProps } = this.props;
    const elementProps = omit(
      restProps,
      'currentUser',
      'isBlocked',
      'dispatch',
      'viewMode',
      'isDemoMode',
      'openModal',
      'isAuthenticated',
      'forPublicUser',
      'isPrivate',
    );

    return (
      <CategoryName {...elementProps} onClick={onClick}>
        {children}
      </CategoryName>
    );
  }
}

export default ensureAuth(ProtectedButton);
