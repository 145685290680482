import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import withFontClassName from '../../hoc/with-font-class-name';
import ButtonTooltip from '../button-tooltip';
import styles from './button.scss';

const SHOW_TIMEOUT = 200;
const HIDE_TIMEOUT = 3000;

class Button extends Component {
  state = {
    isTooltipVisible: false,
  };

  buttonRef = createRef();

  componentWillUnmount() {
    this.hideTooltip();
  }

  showTooltip() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ isTooltipVisible: true }, () => {
        this.timeout = setTimeout(() => this.hideTooltip(), HIDE_TIMEOUT);
      });
    }, SHOW_TIMEOUT);
  }

  hideTooltip() {
    clearTimeout(this.timeout);
    this.setState({
      isTooltipVisible: false,
    });
  }

  handleMouseEnter = () => {
    this.showTooltip();
  };

  handleMouseLeave = () => {
    this.hideTooltip();
  };

  renderTooltip() {
    const { isTooltipVisible } = this.state;
    const { tooltipText } = this.props;

    if (!tooltipText) {
      return null;
    }

    return (
      <ButtonTooltip target={this.buttonRef.current} isVisible={isTooltipVisible}>
        {tooltipText}
      </ButtonTooltip>
    );
  }

  render() {
    const {
      component: Component = 'button',
      className,
      contentFontClassName,
      isSecondary,
      isSubmit,
      isReset,
      isMuted,
      children,
      type,
      useFixedColor,
      keepFocusStyles,
      ...otherProps
    } = this.props;

    const isPrimary = !isSecondary;
    let colorsClassName;

    if (useFixedColor) {
      colorsClassName = isPrimary ? styles.fixedPrimary : styles.fixedSecondary;
    } else {
      colorsClassName = classNames(
        isPrimary ? 'blog-button-background-color' : 'blog-button-border-color',
        isPrimary ? 'blog-button-primary-text-color' : 'blog-button-secondary-text-color',
      );
    }

    const containerClassName = classNames(
      keepFocusStyles && styles.clipboardFocusRing,
      styles.button,
      className,
      contentFontClassName,
      colorsClassName,
      type,
      {
        [styles.secondary]: isSecondary,
        [styles.isMuted]: isMuted,
      },
    );
    const componentProps = omit(
      otherProps,
      'isSubmit',
      'currentUser',
      'isBlocked',
      'dispatch',
      'viewMode',
      'isDemoMode',
      'openModal',
      'isAuthenticated',
      'titleFontClassName',
      'contentFontClassNameWithStyle',
      'metadataFontClassName',
      'tooltipText',
      'isPrivate',
      'forPublicUser',
    );

    return (
      <Component
        className={containerClassName}
        type={(isSubmit && 'submit') || (isReset && 'reset') || 'button'}
        {...componentProps}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.renderTooltip()}
        <span className={styles.wrapper}>{children}</span>
      </Component>
    );
  }
}

Button.TYPE_FULL_WIDTH = styles.fullWidth;
Button.TYPE_FULL_WIDTH_MOBILE = styles.fullWidthMobile;
Button.TYPE_SMALL_SPACINGS_MOBILE = styles.smallSpacingsMobile;
Button.TYPE_LARGE = styles.large;

const allTypes = [
  Button.TYPE_FULL_WIDTH,
  Button.TYPE_FULL_WIDTH_MOBILE,
  Button.TYPE_SMALL_SPACINGS_MOBILE,
  Button.TYPE_LARGE,
];

Button.propTypes = {
  contentFontClassName: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.oneOf(allTypes), PropTypes.array]),
  className: PropTypes.string,
  isSecondary: PropTypes.bool,
  isSubmit: PropTypes.bool,
  isReset: PropTypes.bool,
  isMuted: PropTypes.bool,
  component: PropTypes.any,
  children: PropTypes.node,
  tooltipText: PropTypes.string,
  useFixedColor: PropTypes.bool,
  keepFocusStyles: PropTypes.bool,
};

export default withFontClassName(Button);
