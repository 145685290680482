import React from 'react';
import PropTypes from 'prop-types';

import { getIsPostPageBundle } from '@wix/communities-blog-client-common';

import { getViewMode, getIsDesktop } from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';

const DEFAULT_STYLE = {
  display: 'none',
  visibility: 'hidden',
};

class WindowResizeListener extends React.Component {
  handleResize = () => {
    const { isDesktop, isPostPageBundle, postPageResized, resizeComponent, viewMode } = this.props;

    if (!isDesktop) {
      return;
    }

    let width = window.innerWidth;

    if (width % 2) {
      width = width - 1;
    }

    if (window.innerWidth !== width) {
      resizeComponent({
        width,
        height: window.innerHeight,
      });
    }

    if (isPostPageBundle && this.appWidth !== width) {
      postPageResized({ viewMode, width, buildertype: 'desktop' });
      this.appWidth = width;
    }
  };

  componentDidMount() {
    this.appWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false);
  }

  render() {
    return <div style={DEFAULT_STYLE} />;
  }
}

WindowResizeListener.propTypes = {
  buildertype: PropTypes.string,
  isPostPageBundle: PropTypes.bool,
  postPageResized: PropTypes.func,
  resizeComponent: PropTypes.func,
  viewMode: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isDesktop: getIsDesktop(state),
  isPostPageBundle: getIsPostPageBundle(state),
  postPageResized: actions.postPageResized,
  resizeComponent: host.resizeComponent,
  viewMode: getViewMode(state),
});

export default connect(mapRuntimeToProps)(WindowResizeListener);
