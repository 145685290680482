import React, { useEffect } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  getTPASettignsLiveSiteEditingDeprecated,
  MODAL_TYPE_DELETE_POST,
  MODAL_TYPE_SHARE_POST,
  POST_ACTIONS,
  POST_ACTION_DELETE,
  POST_ACTION_DUPLICATE,
  POST_ACTION_EDIT,
  POST_ACTION_PIN,
  POST_ACTION_PRINT,
  POST_ACTION_SHARE,
  POST_ACTION_SUBSCRIBE,
  POST_ACTION_TOGGLE_COMMENTS,
  POST_TITLE_MAXLENGTH,
  resolveId,
  type ModalType,
  type PostAction,
} from '@wix/communities-blog-client-common';
import useAuth from '../../hooks/use-auth';
import usePermissions from '../../hooks/use-permissions';
import { usePostPageShareProviders } from '../../hooks/use-post-page-share-providers';
import { usePostPrint } from '../../hooks/use-post-print';
import { getRoute } from '../../router/router-selectors';
import { isInPostPage } from '../../services/detect-route';
import { getPostActions } from '../../services/post-actions';
import { isSite as getIsSite } from '../../store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import { getIsPostSubscribed } from '../../store/post-subscription/post-subscription-selectors';
import { type NormalizedPost } from '../../types';
import ActionButton from '../action-button';
import { CommentingDisabledIcon } from '../icons/commenting-disabled-icon';
import { CommentingEnabledIcon } from '../icons/commenting-enabled-icon';
import { DeleteIcon } from '../icons/delete-icon';
import { DuplicateIcon } from '../icons/duplicate-icon';
import { EditIcon } from '../icons/edit-icon';
import { PinIcon } from '../icons/pin-icon';
import { PrintIcon } from '../icons/print-icon';
import { ShareIcon } from '../icons/share-icon';
import { SubscribeIcon } from '../icons/subscribe-icon';
import {
  POST_DISABLE_COMMENTING,
  POST_ENABLE_COMMENTING,
  POST_PIN,
  POST_SUBSCRIBED,
  POST_UNPIN,
  POST_UNSUBSCRIBED,
} from '../message/message-types';
import { useActions, useSelector } from '../runtime-context';
import { useBiClickCallback } from './post-actions-hooks/post-actions-hooks';

type Props = {
  post: NormalizedPost;
  visibleActions?: PostAction[];
};

export const PostActions = React.forwardRef<HTMLDivElement, Props>(
  ({ post, visibleActions }, ref) => {
    useMountEffect();

    const actionsToShow = usePostActions(post, visibleActions);

    return (
      <div ref={ref}>
        {actionsToShow.subscribe && <SubscribeAction post={post} />}
        {actionsToShow.edit && <PostActionsEditAction post={post} />}
        {actionsToShow.share && <ShareAction post={post} />}
        {actionsToShow.print && <PrintAction post={post} />}
        {actionsToShow.pin && <PinAction post={post} />}
        {actionsToShow.toggleComments && <ToggleCommentsAction post={post} />}
        {actionsToShow.delete && <DeleteAction post={post} />}
        {actionsToShow.duplicate && <DuplicateAction post={post} />}
      </div>
    );
  },
);

const PostActionsEditAction: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const isSite = useSelector(getIsSite);
  const { t } = useTranslation();
  const { can } = usePermissions();
  const { forPublicUser } = useAuth();
  const actions = useActions();
  const bi = useBiClickCallback();
  const postId = resolveId(post);

  const handleEdit = () => {
    actions.buttonClicked({ action: 'edit' });
    return actions.goToEditPage(postId);
  };

  return (
    <ActionButton
      biEvent={bi(POST_ACTION_EDIT, post.id)}
      data-hook="post-actions__edit"
      readonly={!isSite}
      onClick={can(POST_ACTION_EDIT, 'post', post) ? handleEdit : forPublicUser(handleEdit)}
    >
      <EditIcon className="blog-icon-fill" />
      {t('post-actions.edit-post')}
    </ActionButton>
  );
};

const SubscribeAction: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const isSite = useSelector(getIsSite);
  const isPostSubscribed = useSelector((s) => getIsPostSubscribed(s, post.id));
  const { t } = useTranslation();
  const { can } = usePermissions();
  const { forPublicUser } = useAuth();
  const actions = useActions();
  const bi = useBiClickCallback();

  const handleSubscription = () => {
    actions.buttonClicked({
      action: 'follow_post',
      flag: Number(!isPostSubscribed),
    });

    if (isPostSubscribed) {
      actions
        .unsubscribePromisified({ id: post.id, type: 'post' })
        .then(() => actions.showMessage(POST_UNSUBSCRIBED));
    } else {
      actions
        .subscribePromisified({ id: post.id, type: 'post' })
        .then(() => actions.showMessage(POST_SUBSCRIBED));
    }
  };

  return (
    <ActionButton
      biEvent={bi(POST_ACTION_SUBSCRIBE, post.id)}
      data-hook="post-actions__subscribe"
      readonly={!isSite}
      onClick={
        can(POST_ACTION_SUBSCRIBE, 'post', post)
          ? handleSubscription
          : forPublicUser(handleSubscription)
      }
    >
      <SubscribeIcon isSubscribed={isPostSubscribed} className="blog-icon-fill" />
      {isPostSubscribed ? t('post-actions.unfollow') : t('post-actions.follow')}
    </ActionButton>
  );
};

const ShareAction: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const isSite = useSelector(getIsSite);
  const { t } = useTranslation();
  const bi = useBiClickCallback();
  const showModalBuilder = useShowModalFnBuilder();

  return (
    <ActionButton
      biEvent={bi(POST_ACTION_SHARE, post.id)}
      data-hook="post-actions__share"
      readonly={!isSite}
      onClick={showModalBuilder(MODAL_TYPE_SHARE_POST, post)}
    >
      <ShareIcon className="blog-icon-fill" />
      {t('post-actions.share-post')}
    </ActionButton>
  );
};

const PrintAction: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const isSite = useSelector(getIsSite);
  const { t } = useTranslation();
  const biClick = useBiClickCallback();

  return (
    <ActionButton
      biEvent={biClick(POST_ACTION_PRINT, post.id)}
      data-hook="post-actions__print"
      readonly={!isSite}
      onClick={window.print}
    >
      <PrintIcon className="blog-icon-fill" />
      {t('post-actions.print')}
    </ActionButton>
  );
};

const PinAction: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const isSite = useSelector(getIsSite);
  const { t } = useTranslation();
  const biClick = useBiClickCallback();
  const actions = useActions();

  const handleClick = () => {
    actions.buttonClicked({
      action: 'pin',
      flag: Number(!post.isPinned),
    });

    if (post.isPinned) {
      actions.unpinPostPromisified(resolveId(post)).then(() => actions.showMessage(POST_UNPIN));
    } else {
      actions.pinPostPromisified(resolveId(post)).then(() => actions.showMessage(POST_PIN));
    }
  };

  return (
    <ActionButton
      biEvent={biClick(POST_ACTION_PIN, post.id)}
      data-hook="post-actions__pin"
      readonly={!isSite}
      onClick={handleClick}
    >
      <PinIcon className="blog-icon-fill" />
      {post.isPinned ? t('post-actions.unpin-post') : t('post-actions.pin-post')}
    </ActionButton>
  );
};

const ToggleCommentsAction: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const isSite = useSelector(getIsSite);
  const { t } = useTranslation();
  const biClick = useBiClickCallback();
  const actions = useActions();

  const handleClick = () => {
    if (post.isCommentsDisabled) {
      actions
        .enablePostCommentsPromisified(resolveId(post))
        .then(() => actions.showMessage(POST_ENABLE_COMMENTING));
    } else {
      actions
        .disablePostCommentsPromisified(resolveId(post))
        .then(() => actions.showMessage(POST_DISABLE_COMMENTING));
    }

    actions.buttonClicked({
      action: 'lock',
      flag: Number(!post.isCommentsDisabled),
    });
  };

  const CommentingStatusIcon = post.isCommentsDisabled
    ? CommentingEnabledIcon
    : CommentingDisabledIcon;

  return (
    <ActionButton
      biEvent={biClick(POST_ACTION_TOGGLE_COMMENTS, post.id)}
      data-hook="post-actions__comments"
      readonly={!isSite}
      onClick={handleClick}
    >
      <CommentingStatusIcon className="blog-icon-fill" />
      {post.isCommentsDisabled
        ? t('post-actions.enable-commenting')
        : t('post-actions.disable-commenting')}
    </ActionButton>
  );
};

const DeleteAction: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const isSite = useSelector(getIsSite);
  const { t } = useTranslation();
  const biClick = useBiClickCallback();
  const showModalBuilder = useShowModalFnBuilder();

  return (
    <ActionButton
      biEvent={biClick(POST_ACTION_DELETE, post.id)}
      data-hook="post-actions__delete"
      readonly={!isSite}
      onClick={showModalBuilder(MODAL_TYPE_DELETE_POST, post)}
    >
      <DeleteIcon className="blog-icon-fill" />
      {t('post-actions.delete-post')}
    </ActionButton>
  );
};

const DuplicateAction: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const isSite = useSelector(getIsSite);
  const { t } = useTranslation();
  const biClick = useBiClickCallback();
  const actions = useActions();

  const handleClick = () => {
    const unsafeDraftTitle = `${t('post-actions.duplicate-post-copy-of')} ${post.title}`;

    actions
      .duplicatePostPromisified({
        title: unsafeDraftTitle.substring(0, POST_TITLE_MAXLENGTH),
        content: post.content,
      })
      .then((duplicatedPost) => actions.goToEditPage(resolveId(duplicatedPost)));
  };

  return (
    <ActionButton
      biEvent={biClick(POST_ACTION_DUPLICATE, post.id)}
      data-hook="post-actions__duplicate"
      readonly={!isSite}
      onClick={handleClick}
    >
      <DuplicateIcon className="blog-icon-fill" />
      {t('post-actions.duplicate-post')}
    </ActionButton>
  );
};

type PostActionDefinition = {
  buttonProps: Pick<
    React.HTMLProps<HTMLButtonElement>,
    'readOnly' | 'aria-disabled' | 'onClick'
  > & { 'data-hook': string };
  icon: React.ComponentType;
  text: string;
};

export function usePostActions(post: NormalizedPost, visibleActions?: PostAction[]) {
  const { canSee } = usePermissions();
  const { isPrintEnabled } = usePostPageShareProviders();

  const isLiveSiteEditorEnabled = !useSelector(getTPASettignsLiveSiteEditingDeprecated);
  const isMembersAreaInstalled = useSelector(getIsMemberAreaInstalled);
  const postActions = getPostActions({
    post,
    canSee,
    actions: (visibleActions || POST_ACTIONS) as PostAction[],
    enableShare: true,
    enableSubscribe: isMembersAreaInstalled,
  });
  const route = useSelector(getRoute);
  const shouldRenderPrint = isInPostPage(route) && isPrintEnabled;

  const isSite = useSelector(getIsSite);
  const isPostSubscribed = useSelector((s) => getIsPostSubscribed(s, post.id));
  const { t } = useTranslation();
  const { can } = usePermissions();
  const { forPublicUser } = useAuth();
  const actions = useActions();
  const bi = useBiClickCallback();
  const showModalBuilder = useShowModalFnBuilder();
  const print = usePostPrint();

  const handleSubscription = () => {
    bi(POST_ACTION_SUBSCRIBE, post.id)();
    actions.buttonClicked({
      action: 'follow_post',
      flag: Number(!isPostSubscribed),
    });

    if (isPostSubscribed) {
      actions
        .unsubscribePromisified({ id: post.id, type: 'post' })
        .then(() => actions.showMessage(POST_UNSUBSCRIBED));
    } else {
      actions
        .subscribePromisified({ id: post.id, type: 'post' })
        .then(() => actions.showMessage(POST_SUBSCRIBED));
    }
  };

  const handlePrintClick = () => {
    bi(POST_ACTION_PRINT, post.id)();
    print();
  };

  const editPost = () => {
    bi(POST_ACTION_EDIT, post.id)();
    actions.buttonClicked({ action: 'edit' });
    actions.goToEditPage(resolveId(post));
  };

  const handleEditClick = can(POST_ACTION_EDIT, 'post', post) ? editPost : forPublicUser(editPost);

  const handlePinClick = () => {
    bi(POST_ACTION_PIN, post.id)();
    actions.buttonClicked({
      action: 'pin',
      flag: Number(!post.isPinned),
    });

    if (post.isPinned) {
      actions.unpinPostPromisified(resolveId(post)).then(() => actions.showMessage(POST_UNPIN));
    } else {
      actions.pinPostPromisified(resolveId(post)).then(() => actions.showMessage(POST_PIN));
    }
  };

  const handleToggleCommentsClick = () => {
    bi(POST_ACTION_TOGGLE_COMMENTS, post.id)();
    if (post.isCommentsDisabled) {
      actions
        .enablePostCommentsPromisified(resolveId(post))
        .then(() => actions.showMessage(POST_ENABLE_COMMENTING));
    } else {
      actions
        .disablePostCommentsPromisified(resolveId(post))
        .then(() => actions.showMessage(POST_DISABLE_COMMENTING));
    }

    actions.buttonClicked({
      action: 'lock',
      flag: Number(!post.isCommentsDisabled),
    });
  };

  const handleDeleteClick = () => {
    bi(POST_ACTION_DELETE, post.id)();
    showModalBuilder(MODAL_TYPE_DELETE_POST, post)();
  };

  const handleDuplicateClick = () => {
    bi(POST_ACTION_DUPLICATE, post.id)();
    const unsafeDraftTitle = `${t('post-actions.duplicate-post-copy-of')} ${post.title}`;

    actions
      .duplicatePostPromisified({
        title: unsafeDraftTitle.substring(0, POST_TITLE_MAXLENGTH),
        content: post.content,
      })
      .then((duplicatedPost) => actions.goToEditPage(resolveId(duplicatedPost)));
  };

  const handleShareClick = () => {
    bi(POST_ACTION_SHARE, post.id)();
    showModalBuilder(MODAL_TYPE_SHARE_POST, post)();
  };

  const handleSubscribeClick = can(POST_ACTION_SUBSCRIBE, 'post', post)
    ? handleSubscription
    : forPublicUser(handleSubscription);

  const commonButtonProps: Pick<
    React.HTMLProps<HTMLButtonElement>,
    'readOnly' | 'aria-disabled'
  > = {
    readOnly: !isSite,
    'aria-disabled': !isSite ? 'true' : undefined,
  } as const;

  return {
    print: shouldRenderPrint
      ? {
          buttonProps: {
            ...commonButtonProps,
            onClick: handlePrintClick,
            'data-hook': 'post-actions__print',
          },
          icon: PrintIcon,
          text: t('post-actions.print'),
        }
      : undefined,
    edit:
      postActions.includes(POST_ACTION_EDIT) && isLiveSiteEditorEnabled
        ? {
            buttonProps: {
              ...commonButtonProps,
              onClick: handleEditClick,
              'data-hook': 'post-actions__edit',
            },
            icon: EditIcon,
            text: t('post-actions.edit-post'),
          }
        : undefined,
    pin:
      isLiveSiteEditorEnabled && postActions.includes(POST_ACTION_PIN)
        ? {
            buttonProps: {
              ...commonButtonProps,
              onClick: handlePinClick,
              'data-hook': 'post-actions__pin',
            },
            icon: PinIcon,
            text: post.isPinned ? t('post-actions.unpin-post') : t('post-actions.pin-post'),
          }
        : undefined,
    toggleComments:
      isLiveSiteEditorEnabled && postActions.includes(POST_ACTION_TOGGLE_COMMENTS)
        ? {
            buttonProps: {
              ...commonButtonProps,
              onClick: handleToggleCommentsClick,
              'data-hook': 'post-actions__comments',
            },
            icon: post.isCommentsDisabled ? CommentingEnabledIcon : CommentingDisabledIcon,
            text: post.isCommentsDisabled
              ? t('post-actions.enable-commenting')
              : t('post-actions.disable-commenting'),
          }
        : undefined,
    delete:
      isLiveSiteEditorEnabled && postActions.includes(POST_ACTION_DELETE)
        ? {
            buttonProps: {
              ...commonButtonProps,
              onClick: handleDeleteClick,
              'data-hook': 'post-actions__delete',
            },
            icon: DeleteIcon,
            text: t('post-actions.delete-post'),
          }
        : undefined,
    duplicate:
      isLiveSiteEditorEnabled && postActions.includes(POST_ACTION_DUPLICATE)
        ? {
            buttonProps: {
              ...commonButtonProps,
              onClick: handleDuplicateClick,
              'data-hook': 'post-actions__duplicate',
            },
            icon: DuplicateIcon,
            text: t('post-actions.duplicate-post'),
          }
        : undefined,
    share: postActions.includes(POST_ACTION_SHARE)
      ? {
          buttonProps: {
            ...commonButtonProps,
            onClick: handleShareClick,
            'data-hook': 'post-actions__share',
          },
          icon: ShareIcon,
          text: t('post-actions.share-post'),
        }
      : undefined,
    subscribe: postActions.includes(POST_ACTION_SUBSCRIBE)
      ? {
          buttonProps: {
            ...commonButtonProps,
            onClick: handleSubscribeClick,
            'data-hook': 'post-actions__subscribe',
          },
          icon: SubscribeIcon,
          text: isPostSubscribed ? t('post-actions.unfollow') : t('post-actions.follow'),
        }
      : undefined,
  } as const satisfies Record<string, PostActionDefinition | undefined>;
}

function useMountEffect() {
  const actions = useActions();

  useEffect(() => {
    actions.actionsOpened({ type: 'post' });

    return () => {
      actions.actionsClosed({ type: 'post' });
    };
  });
}

function useShowModalFnBuilder() {
  const actions = useActions();

  return (type: ModalType, post: NormalizedPost) => () => {
    actions.buttonClicked({ action: type, postId: resolveId(post) });
    actions.openModal(type, {
      postId: resolveId(post),
      postSlug: post.slug,
      postStatus: post.status,
    });
  };
}

export default PostActions;
