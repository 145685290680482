import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { type AppState } from '../../../types';

const getModalState = (state: AppState) => state.modal;

export const getModal = createSelector(
  [getModalState, (state: AppState, id: string) => id],
  (modalState, id) => modalState.openedModals[id],
);

export const getOpenedModals = createSelector(
  [getModalState],
  (modalState) => modalState.openedModals,
);

export const getIsModalDisplayed = createSelector(
  [getOpenedModals, (state: AppState, id: string) => id],
  (openedModals, id) => !isEmpty(id ? openedModals : openedModals[id]),
);
