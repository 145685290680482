import { urijs } from '@wix/communities-blog-client-common';

export const getVideoEmbedThumbUrl = (oembed: any) => {
  if (!oembed) {
    return '';
  }

  // @ts-expect-error
  return urijs(oembed.thumbnail_url).protocol('').toString();
};
