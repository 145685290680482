import React, { Component } from 'react';
import { RootPropsContext } from './root-props-context';

export const withRootProps = (WrappedComponent) => {
  class WithRootProps extends Component {
    render() {
      return (
        <RootPropsContext.Provider value={this.props}>
          <WrappedComponent {...this.props} />
        </RootPropsContext.Provider>
      );
    }
  }

  return WithRootProps;
};
