import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';
import styles from './no-post-found.scss';

const NoPostFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article data-hook="empty-state-container" className={styles.root}>
      <h2 data-hook="empty-states__title">{t('no-post-found.title')}</h2>
      <p>{t('no-post-found.message')}</p>
    </article>
  );
};

export default NoPostFound;
