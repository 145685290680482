import React, { type ComponentProps } from 'react';
import { getCategories } from '../../store/categories/categories-selectors';
import { HeaderMobile } from '../header';
import { useSelector } from '../runtime-context';

type Props = {
  menu?: React.ReactNode;
} & ComponentProps<typeof HeaderMobile>;

const CategoryMobileHeader: React.FC<Props> = ({ menu, ...otherProps }) => {
  const categories = useSelector(getCategories);

  if (categories.length) {
    return <HeaderMobile menu={menu} {...otherProps} />;
  }

  return <HeaderMobile {...otherProps} />;
};

export default CategoryMobileHeader;
