import { once } from 'lodash';

export const importPrivateProfileActions = once(async () => {
  return (
    await import(
      /* webpackChunkName: "private-profile-actions" */
      './private-profile-actions'
    )
  ).default;
});
