import { useEffect } from 'react';
import { useActions, useSelector } from '../components/runtime-context';
import { getIsMetadataLoaded } from '../store/is-loaded/is-loaded-selectors';
import { type NormalizedPost } from '../types';

export const useLoadedMetadataForPost = (postSlug: NormalizedPost['slug']) => {
  const actions = useActions();
  const isMetadataLoaded = useSelector(getIsMetadataLoaded);

  useEffect(() => {
    if (postSlug && !isMetadataLoaded) {
      actions.fetchPostMetadata(postSlug);
    }
  }, [actions, isMetadataLoaded, postSlug]);

  return isMetadataLoaded;
};
