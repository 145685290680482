import React from 'react';
import { useTranslation, useEnvironment, useBi } from '@wix/yoshi-flow-editor';
import { type BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import { clickOnTag } from '@wix/bi-logger-blog-data/v2';
import { useSelector } from '@app/external/common/components/runtime-context';
import { getTopology } from '@app/external/common/store/topology/topology-selectors';
import { type NormalizedPost } from '@app/external/common/types';
import styles from './post-page-post-tags.scss';

type Props = {
  post: NormalizedPost;
};

const PostPagePostTags: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isRTL } = useEnvironment();
  const bi = useBi();

  const topology = useSelector(getTopology);

  const tags = props.post.tags ?? [];

  if (tags.length === 0) {
    return null;
  }

  const handleClickForBi = (tag: BlogTag) => {
    bi.report(
      clickOnTag({
        tag_name: tag.label,
        tagid: tag.id,
      }),
    );
  };

  return (
    <section className={styles.root}>
      <p className={styles.tagsLabel}>{t('post-page.tags-label')}</p>
      <nav
        dir={isRTL ? 'rtl' : 'ltr'}
        aria-label={t('tag-cloud.aria-label')}
        data-hook="tag-cloud-root"
      >
        <ul className={styles.list}>
          {tags.map((tag) => {
            return (
              <li key={tag.id}>
                <a
                  onClick={() => handleClickForBi(tag)}
                  href={`${topology.sectionUrl!}/tags/${tag.slug}`}
                  className={styles.tag}
                  rel="noopener noreferrer"
                >
                  {tag.label}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </section>
  );
};

export default PostPagePostTags;
