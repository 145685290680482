import React, { useMemo } from 'react';
import { type RicosTheme } from '@wix/ricos';
import loadable from '@wix/yoshi-flow-editor/loadable';
import {
  createRicosThemeWithCssVars,
  createRicosThemeWithCssVarsForPaywall,
} from '@wix/communities-blog-client-common';
import { useSelector } from '@app/external/common/components/runtime-context';
import { getIsMobile } from '@app/external/common/store/basic-params/basic-params-selectors';
import { type NormalizedPost } from '@app/external/common/types';
import type IRichContentViewer from '@app/external/rich-content/components/rich-content-viewer';
import { usePostPaywallContent } from './use-post-paywall-content';
import styles from './post-page-post-content.scss';

const RichContentViewer = loadable(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "rich-content-viewer" */ '@app/external/rich-content/components/rich-content-viewer'
    ),
  {
    fallback: null,
  },
) as typeof IRichContentViewer;

type PostContentProps = {
  post: NormalizedPost;
};

const PostPagePostContent: React.FC<PostContentProps> = ({ post }) => {
  const isMobile = useSelector(getIsMobile);
  const theme = useMemo(
    (): RicosTheme => ({
      ...createRicosThemeWithCssVars(isMobile),
      parentClass: 'post-content__body',
    }),
    [isMobile],
  );

  return (
    <>
      {typeof post.content === 'object' && (
        <RichContentViewer initialRawState={post.content} post={post} theme={theme} />
      )}
      {post?.canSeePaidContent === false && post.paywallRichContent && (
        <div className={styles.paywallContainer}>
          <RichContentViewerWithPaywall post={post} />
        </div>
      )}
    </>
  );
};

type PaywallBannerWithLinkProps = {
  post: NormalizedPost;
};

// #region Paywall banner
const RichContentViewerWithPaywall: React.FC<PaywallBannerWithLinkProps> = ({ post }) => {
  const theme = useMemo(() => createRicosThemeWithCssVarsForPaywall(), []);
  const paywallContentWithSubscribeUrl = usePostPaywallContent(post);

  return (
    <div className={styles.banner}>
      <RichContentViewer
        theme={theme}
        initialRawState={paywallContentWithSubscribeUrl!}
        parentClass={styles.banner}
        post={post}
      />
    </div>
  );
};

export default PostPagePostContent;
