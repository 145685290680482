import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  getCustomPostUrl,
  getOuterUrl,
  getPostPageSectionUrl,
  getTPASettingsIsCommentsEnabled,
  openShareDialog,
  resolveId,
} from '@wix/communities-blog-client-common';
import { BUTTON_SHARE_POST } from '@app/external/common/bi-events/bi-buttons';
import { Counter } from '@app/external/common/components/counter';
import { CommentIcon } from '@app/external/common/components/icons/comment-icon';
import { ViewCountIcon } from '@app/external/common/components/icons/view-count-icon';
import { LikeButtonWithCount } from '@app/external/common/components/like-button-with-count';
import PostLink from '@app/external/common/components/link/post-link';
import { useActions, useSelector } from '@app/external/common/components/runtime-context';
import { SkeletonRenderer } from '@app/external/common/components/skeleton-renderer';
import { useLoadedMetadataForPost } from '@app/external/common/hooks/use-loaded-metadata-for-post';
import { usePostPrint } from '@app/external/common/hooks/use-post-print';
import { getSocialSharingIcon } from '@app/external/common/services/social-sharing-icons';
import {
  getCommentCount,
  getViewCount,
} from '@app/external/common/store/post-counters/post-counters-selectors';
import { type NormalizedPost } from '@app/external/common/types';
import { useFullPostLayoutOverrides } from './feed-overrides-context';
import styles from './post-page-post-footer-actions.scss';

type PostFooterActionsProps = {
  post: NormalizedPost;
};

const SKELETON_WIDTH_FOR_COUNTERS = 40;

const PostPagePostFooterActions: React.FC<PostFooterActionsProps> = ({ post }) => {
  const postId = resolveId(post)!;
  const viewCount = useSelector((state) => getViewCount(state, postId));
  const totalComments = useSelector((state) => getCommentCount(state, postId));
  const overrides = useFullPostLayoutOverrides();
  const isMetadataLoaded = useLoadedMetadataForPost(post.slug);
  const isCommentsEnabledInTpaSettings = useSelector(getTPASettingsIsCommentsEnabled);

  return (
    <section className={styles.root} data-hook="post-main-actions-desktop">
      {overrides.showShareSection && (
        <div className={styles.sectionShare}>
          <div className={styles.shareIconsRow}>
            {overrides.showShareOnFacebook && <ShareIconButton post={post} type="facebook" />}
            {overrides.showShareOnX && <ShareIconButton post={post} type="twitter" />}
            {overrides.showShareOnLinkedIn && <ShareIconButton post={post} type="linked-in" />}
            {overrides.showShareOnLink && <ShareIconButton post={post} type="link" />}
            {overrides.showShareOnPrint && <ShareIconButton post={post} type="print" />}
          </div>
        </div>
      )}
      {overrides.showStatsSection && (
        <div className={styles.sectionCounts} data-hook="post-main-actions__stats">
          <div data-hook="post-stats" className={styles.stats}>
            {overrides.showViewCount && (
              <SkeletonRenderer
                className={styles.viewCount}
                isLoaded={isMetadataLoaded}
                width={SKELETON_WIDTH_FOR_COUNTERS}
              >
                <CounterWithIconOnMobile
                  Icon={ViewCountIcon}
                  count={viewCount}
                  i18nKey="view-count.label"
                />
              </SkeletonRenderer>
            )}
            {overrides.showCommentCount && isCommentsEnabledInTpaSettings && (
              <>
                <SkeletonRenderer
                  className={styles.commentCount}
                  isLoaded={isMetadataLoaded}
                  width={SKELETON_WIDTH_FOR_COUNTERS}
                >
                  {overrides.isInPostPage ? (
                    <CounterWithIconOnMobile
                      Icon={CommentIcon}
                      count={totalComments}
                      i18nKey="comment-count.label"
                    />
                  ) : (
                    <PostLink postLink={post.link} postPath={post.slug!}>
                      <CounterWithIconOnMobile
                        Icon={CommentIcon}
                        count={totalComments}
                        i18nKey="comment-count.label"
                      />
                    </PostLink>
                  )}
                </SkeletonRenderer>
              </>
            )}
          </div>
          {overrides.showLikeCount && (
            <SkeletonRenderer
              isLoaded={isMetadataLoaded}
              width={SKELETON_WIDTH_FOR_COUNTERS}
              className={styles.likeButton}
            >
              <LikeButtonWithCount key={postId} postId={postId} />
            </SkeletonRenderer>
          )}
        </div>
      )}
    </section>
  );
};

type CounterWithIconOnMobileProps = {
  Icon: React.ComponentType;
  count: number;
  i18nKey: string;
  className?: string;
};

const CounterWithIconOnMobile = ({
  Icon,
  count,
  i18nKey,
  className,
}: CounterWithIconOnMobileProps) => {
  const { t } = useTranslation();

  return (
    <div className={className} aria-label={t(i18nKey, { count })} role="status">
      <div className={styles.counterIconRow} aria-hidden="true">
        <Icon />
        {count}
      </div>
      <Counter className={styles.counterText} count={count} i18nKey={i18nKey} showZero />
    </div>
  );
};

const useTooltip = (initialState = false, timeout = 2000) => {
  const [showTooltip, setShowTooltip] = useState(initialState);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (showTooltip) {
      timer = setTimeout(() => setShowTooltip(false), timeout);
    }

    return () => clearTimeout(timer);
  }, [showTooltip, timeout]);

  return [showTooltip, setShowTooltip] as const;
};

type PostShareButtonProps = {
  type: 'facebook' | 'twitter' | 'linked-in' | 'link' | 'print';
  post: NormalizedPost;
};

const SHOW_TOOLTIP_TIMEOUT = 2000;

const ShareIconButton: React.FC<PostShareButtonProps> = ({ type, post }) => {
  const { t } = useTranslation();
  const { isViewer } = useEnvironment();
  const SvgIcon = getSocialSharingIcon(type);
  const [showTooltip, setShowTooltip] = useTooltip(false, SHOW_TOOLTIP_TIMEOUT);
  const link = type === 'link';
  const TooltipComponent = isViewer ? (link ? Tooltip : 'div') : Tooltip;

  const print = usePostPrint();

  const ariaLabelMap: Record<typeof type, string> = {
    facebook: 'share-button.via-facebook',
    twitter: 'share-button.via-twitter',
    'linked-in': 'share-button.via-linked-in',
    link: 'share-button.via-link',
    print: 'post-actions.print',
  };

  const ariaLabel = t(ariaLabelMap[type]);

  const sectionUrl = useSelector(getPostPageSectionUrl);
  const customPostUrl = useSelector((state) => getCustomPostUrl(state, post.slug));
  const url = customPostUrl || getOuterUrl(`/${post.slug}`, sectionUrl);

  const actions = useActions();

  const handleShare = () => {
    if (!isViewer) {
      return;
    }

    actions.postPageSocialShareClicked({ postId: post.id, type });

    if (type === 'print') {
      print();
      return;
    }

    actions.buttonClicked({ action: BUTTON_SHARE_POST, postId: post.id, shareType: type });

    if (link) {
      navigator.clipboard.writeText(url);
      setShowTooltip(true);
    } else {
      openShareDialog(type, url);
    }
  };

  const getContent = () => {
    if (link && showTooltip) {
      return t('share-link.copied');
    }

    if (isViewer) {
      return '';
    }

    return t('post-social-actions.preview-mode-tooltip');
  };

  return (
    <>
      {link && showTooltip ? (
        <span className={styles.srOnly} role="alert">
          {t('share-link.copied')}
        </span>
      ) : null}
      <TooltipComponent
        className={classNames(styles.shareButtonWrapper, styles[type])}
        content={getContent()}
        minWidth={!isViewer ? 200 : undefined}
        contentClassName={isViewer ? styles.tooltipContent : undefined}
        disabled={isViewer && (type !== 'link' || !showTooltip)}
        shown={link && showTooltip ? true : undefined}
      >
        <button
          className={styles.shareButton}
          onClick={handleShare}
          aria-label={ariaLabel}
          data-hook={`share-button__${type}`}
        >
          <SvgIcon className={styles.shareButtonIcon} />
          <span className={styles.shareButtonClickableArea} />
        </button>
      </TooltipComponent>
    </>
  );
};

export default PostPagePostFooterActions;
