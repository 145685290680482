import React from 'react';
import { Trans } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { getTPASettingsIsRatingsEnabled, resolveId } from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Post/stylesParams';
import PostLink from '@app/external/common/components/link/post-link';
import useSelector from '@app/external/common/components/runtime-context/use-selector';
import TimeAgo from '@app/external/common/components/time-ago';
import { usePostTitleTag } from '@app/external/common/context/post-title-tag-context';
import { isSameDay } from '@app/external/common/helpers/date';
import { getPostTotalRatings } from '@app/external/common/store/post-ratings/post-ratings-selector';
import { type NormalizedPost } from '@app/external/common/types';

import { isTextDirectionRTL } from '@app/external/rich-content/services/text-direction';
import { useFullPostLayoutOverrides } from './feed-overrides-context';
import PostPagePostHeaderMetadata from './post-page-post-header-metadata';
import PostPagePostHeaderMoreActions from './post-page-post-header-more-actions';
import PostPagePostHeaderRatings from './post-page-post-header-ratings';
import styles from './post-page-post-header.scss';

type Props = {
  post: NormalizedPost;
  onRatingsDisplayClick?: () => void;
};

const PostPagePostHeader: React.FC<Props> = (props) => {
  const allBreakpoints = useStylesForAllBreakpoints();
  const overrides = useFullPostLayoutOverrides();

  const isRatingsEnabledInTpaSettings = useSelector(getTPASettingsIsRatingsEnabled);
  const postTotalRatings = useSelector((state) =>
    getPostTotalRatings(state, resolveId(props.post)),
  );
  const showRatings = overrides.isInPostPage
    ? overrides.showPostRating && isRatingsEnabledInTpaSettings
    : overrides.showPostRating && isRatingsEnabledInTpaSettings && !!postTotalRatings;

  return (
    <>
      <div className={styles.postTitleContainer}>
        <div className={styles.titleWrapper} data-hook="post-title">
          {allBreakpoints.isTitleHidden ? null : <PostPagePostHeaderTitle post={props.post} />}
        </div>
        <div className={styles.topWrapper}>
          <PostPagePostHeaderMetadata post={props.post} />
          <PostPagePostHeaderMoreActions post={props.post} />
        </div>
      </div>
      {overrides.showPostUpdatedDate && isPostUpdatedAfterFirstPublish(props.post) ? (
        <p className={styles.updateDate}>
          <Trans
            i18nKey="post.update-date-new"
            components={[<TimeAgo time={props.post.lastPublishedDate} />]}
          />
        </p>
      ) : null}
      {showRatings && (
        <PostPagePostHeaderRatings post={props.post} onClick={props.onRatingsDisplayClick} />
      )}
    </>
  );
};

const PostPagePostHeaderTitle: React.FC<{
  post: NormalizedPost;
}> = ({ post }) => {
  const { tagName: Component } = usePostTitleTag();
  const overrides = useFullPostLayoutOverrides();

  return (
    <Component
      className={styles.title}
      data-hook="post-title"
      dir={typeof post.title === 'string' && isTextDirectionRTL(post.title) ? 'rtl' : undefined}
    >
      {overrides.isInPostPage ? (
        <>{post.title}</>
      ) : (
        <PostLink postLink={post.link} postPath={post.slug!} className={styles.titleLink}>
          {post.title}
        </PostLink>
      )}
    </Component>
  );
};

function useStylesForAllBreakpoints() {
  const wixStyles = useStyles();
  const stylesForAllBreakpoints = wixStyles.getStylesForAllBreakpoints();
  const isTitleHidden = stylesForAllBreakpoints.every((d) => {
    const keyOnDesktop = stylesParams.showPostTitle.key!;
    const keyOnMobile = `${stylesParams.showPostTitle.key!}▶︎m`;

    if (keyOnMobile in d.booleans) {
      return d.booleans[keyOnDesktop] === false && d.booleans[keyOnMobile] === false;
    }

    return d.booleans[keyOnDesktop] === false;
  });

  return { isTitleHidden };
}

const isPostUpdatedAfterFirstPublish = (post: NormalizedPost) => {
  return Boolean(
    post.firstPublishedDate &&
      post.lastPublishedDate &&
      !isSameDay(post.firstPublishedDate, post.lastPublishedDate),
  );
};

export default PostPagePostHeader;
