export const ARCHIVE_WIDGET_ID = '2f3d2c69-2bc4-4519-bd72-0a63dd92577f';

export const BLOG_WIDGET_ID = '14c1462a-97f2-9f6a-7bb7-f5541f23caa6';

export const CATEGORY_MENU_WIDGET_ID = 'a0d7808c-0d7d-4a40-8cf0-911a9f0de96f';

export const FEED_WIDGET_ID = '14c1462a-97f2-9f6a-7bb7-f5541f23caa6';

export const MY_POSTS_WIDGET_ID = 'e5a2773b-0e6b-4cbb-a012-3b4a69e92046';

export const POST_LIST_WIDGET_ID = '813eb645-c6bd-4870-906d-694f30869fd9';

export const POST_WIDGET_ID = '211b5287-14e2-4690-bb71-525908938c81';

export const RSS_BUTTON_WIDGET_ID = '1515a9e7-b579-fbbb-43fc-0e3051c14803';

export const TAG_CLOUD_WIDGET_ID = 'c0a125b8-2311-451e-99c5-89b6bba02b22';

export const COMMENTS_WIDGET_ID = '14f260f9-c2eb-50e8-9b3c-4d21861fe58f';

export const LIKES_WIDGET_ID = '14f26109-514f-f9a8-9b3c-4d21861fe58f';

export const RELATED_POSTS_WIDGET_ID = '46a9e991-c1cc-47c9-b19a-e99d3be1e2c9';

export const POST_PAGE_ID = '25ab36f9-f8bd-4799-a887-f10b6822fc2e';

export const CATEGORY_HEADER_WIDGET_ID = '97466558-6e7b-43e6-9734-82123ef4c3f3';
