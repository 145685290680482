import { useEffect, useMemo } from 'react';
import { throttle } from 'lodash';

const useScrollListener = (onScroll: ((e: Event) => void) | undefined, throttleTimeout = 500) => {
  const onScrollThrottled = useMemo(() => {
    if (!onScroll) {
      return () => {};
    }

    return throttle(onScroll, throttleTimeout, {
      leading: true,
    });
  }, [onScroll, throttleTimeout]);

  useEffect(() => {
    document.addEventListener('scroll', onScrollThrottled, {
      passive: true,
    });

    return () => {
      document.removeEventListener('scroll', onScrollThrottled);
    };
  }, [onScrollThrottled]);
};

export default useScrollListener;
