import React, { useMemo } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getCurrentSitePageTitle } from '../../store/basic-params/basic-params-selectors';
import Link from '../link/internal-link';
import { useSelector } from '../runtime-context';
import styles from './header-title.scss';

type Props = {
  to?: string;
};

export const HeaderTitle: React.FC<Props> = ({ to }) => {
  const { t } = useTranslation();
  const title: string = useSelector(getCurrentSitePageTitle);

  const Component = useMemo(() => (to === undefined ? 'span' : Link), [to]);
  const translatedTitle = useMemo(
    () =>
      ({
        Blog: t('blog-mobile-header.title.blog'),
        Post: t('blog-mobile-header.title.post'),
        Category: t('blog-mobile-header.title.category'),
      }[title] || title),
    [t, title],
  );

  return (
    <p className={styles.title}>
      <Component to={to}>{translatedTitle}</Component>
    </p>
  );
};

export default HeaderTitle;
