import Message from '../../../common/components/message';
import messages from '../../../common/components/message/message-map-by-type';
import styles from '../../../common/components/message/message-root.scss';
import { createMessageRoot } from '../../../common/messages/framework/components/message-root';

export default createMessageRoot({
  MessageComponent: Message,
  messages,
  className: styles.container,
});
