import { type NormalizedCategory, resolveId } from '@wix/communities-blog-client-common';

export type HeaderLink = {
  key: string;
  path: string;
  text: string;
};

type Params = {
  categories: NormalizedCategory[];
  feedTitle: string;
  categoryPath: string;
  withLinkToAllPosts: boolean;
};

export const LATEST_POSTS = 'latest_posts';

export const getHeaderLinks = ({
  categories,
  feedTitle,
  categoryPath,
  withLinkToAllPosts,
}: Params): HeaderLink[] => {
  const linkToAllPosts: HeaderLink = {
    key: LATEST_POSTS,
    path: '/',
    text: feedTitle,
  };
  const linksToCategories: HeaderLink[] = categories
    .filter((category) => category?.slug)
    .map((category) => ({
      key: resolveId(category),
      path: `/${categoryPath}/${category.slug}`,
      text: category.menuLabel || category.label!,
    }));

  return withLinkToAllPosts ? [linkToAllPosts, ...linksToCategories] : linksToCategories;
};
