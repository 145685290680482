import React from 'react';
import { Trans } from '@wix/yoshi-flow-editor';
import {
  RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY,
  RCE_FILE_TOO_BIG,
  RCE_FILE_UPLOAD_LIMITED,
  RCE_FILE_UPLOAD_FAILURE,
} from '@wix/communities-blog-client-common';
import {
  CATEGORY_CREATE,
  CATEGORY_DELETE,
  CATEGORY_SUBSCRIBED,
  CATEGORY_UNSUBSCRIBED,
  COMMENT_DELETE,
  COMMENT_UPDATE,
  DRAFT_DELETE,
  DRAFT_DUPLICATE,
  DRAFT_MOVE_TO_TRASH,
  LOGO_SAVED,
  MEMBER_BLOCK,
  MEMBER_DEMOTE_WRITER,
  MEMBER_PROMOTE_WRITER,
  MEMBER_UNBLOCK,
  NOT_PUBLISHED_BLOG_POST_PUBLISH,
  POST_CHANGES_DISCARDED,
  POST_CHANGES_NOT_PUBLISHED,
  POST_CHANGE_CATEGORY,
  POST_DELETE,
  POST_DELETE_ANOTHER_WRITER_EDITING_ERROR,
  POST_DISABLE_COMMENTING,
  POST_DUPLICATE,
  POST_ENABLE_COMMENTING,
  POST_MOVE,
  POST_MOVE_TO_TRASH,
  POST_PIN,
  POST_PUBLISH,
  POST_RESTORE,
  POST_REVERTED_TO_DRAFT,
  POST_SUBMITTED_FOR_MODERATION,
  POST_SUBSCRIBED,
  POST_UNPIN,
  POST_UNSUBSCRIBED,
  POST_UPDATE,
  POST_UPDATED_IN_MODERATION,
  PROFILE_UPDATE,
  PROFILE_UPDATE_FAILED,
  REQUIRE_CATEGORY_FIELDS,
  REQUIRE_POST_FIELDS,
  REQUIRE_PROFILE_FIELDS,
  SETTINGS_UPDATED,
  USER_SUBSCRIBED,
  USER_UNSUBSCRIBED,
  TECHNICAL_ISSUE_ERROR,
} from './message-types';

const Content = ({ i18nKey }) => (
  <span>
    <Trans i18nKey={i18nKey} />
  </span>
);

export default {
  [POST_RESTORE]: {
    content: () => <Content i18nKey="messages.post-restored" />,
    type: 'success',
  },
  [POST_MOVE_TO_TRASH]: {
    content: () => <Content i18nKey="messages.post-moved-to-trash" />,
    type: 'success',
  },
  [POST_PIN]: {
    content: () => <Content i18nKey="messages.post-pinned" />,
    type: 'info',
  },
  [POST_UNPIN]: {
    content: () => <Content i18nKey="messages.post-unpinned" />,
    type: 'info',
  },
  [POST_DELETE]: {
    content: () => <Content i18nKey="messages.post-deleted" />,
    type: 'success',
  },
  [POST_DELETE_ANOTHER_WRITER_EDITING_ERROR]: {
    content: () => <Content i18nKey="messages.post-delete-another-writer-editing-error" />,
    type: 'error',
  },
  [POST_PUBLISH]: {
    content: () => <Content i18nKey="messages.post-published" />,
    type: 'success',
  },
  [POST_REVERTED_TO_DRAFT]: {
    content: () => <Content i18nKey="messages.post-reverted-to-draft" />,
    type: 'success',
  },
  [POST_CHANGES_DISCARDED]: {
    content: () => <Content i18nKey="messages.post-changes-discarded" />,
    type: 'success',
  },
  [POST_CHANGES_NOT_PUBLISHED]: {
    content: () => <Content i18nKey="messages.post-changes-not-published" />,
    type: 'info',
  },
  [POST_UPDATE]: {
    content: () => <Content i18nKey="messages.post-updated" />,
    type: 'success',
  },
  [NOT_PUBLISHED_BLOG_POST_PUBLISH]: {
    type: 'info',
  },
  [POST_ENABLE_COMMENTING]: {
    content: () => <Content i18nKey="messages.commenting-enabled" />,
    type: 'info',
  },
  [POST_DISABLE_COMMENTING]: {
    content: () => <Content i18nKey="messages.commenting-disabled" />,
    type: 'info',
  },
  [POST_DUPLICATE]: {
    content: () => <Content i18nKey="messages.post-duplicated" />,
    type: 'success',
  },
  [DRAFT_MOVE_TO_TRASH]: {
    content: () => <Content i18nKey="messages.draft-moved-to-trash" />,
    type: 'success',
  },
  [DRAFT_DELETE]: {
    content: () => <Content i18nKey="messages.draft-deleted" />,
    type: 'success',
  },
  [DRAFT_DUPLICATE]: {
    content: () => <Content i18nKey="messages.draft-duplicated" />,
    type: 'success',
  },
  [USER_SUBSCRIBED]: {
    content: () => <Content i18nKey="messages.user-subscribed" />,
    type: 'info',
  },
  [USER_UNSUBSCRIBED]: {
    content: () => <Content i18nKey="messages.user-unsubscribed" />,
    type: 'info',
  },
  [POST_SUBSCRIBED]: {
    content: () => <Content i18nKey="messages.post-subscribed" />,
    type: 'info',
  },
  [POST_UNSUBSCRIBED]: {
    content: () => <Content i18nKey="messages.post-unsubscribed" />,
    type: 'info',
  },
  [POST_MOVE]: {
    content: () => <Content i18nKey="messages.post-moved" />,
  },
  [POST_CHANGE_CATEGORY]: {
    content: () => <Content i18nKey="messages.post-category-changed" />,
  },

  [COMMENT_UPDATE]: {
    content: () => <Content i18nKey="messages.comment-updated" />,
    type: 'info',
  },
  [COMMENT_DELETE]: {
    content: () => <Content i18nKey="messages.comment-deleted" />,
    type: 'info',
  },

  [REQUIRE_POST_FIELDS]: {
    content: (t, props) => {
      const key = props && props.sort().join('-');
      switch (key) {
        case 'title':
          return <Content i18nKey="messages.post-title-required" />;
        case 'content':
          return <Content i18nKey="messages.post-content-required" />;
        default:
          return <Content i18nKey="messages.post-fields-required" />;
      }
    },
    type: 'error',
  },

  [REQUIRE_CATEGORY_FIELDS]: {
    content: (t, props) => {
      const key = props && props.sort().join('-');
      switch (key) {
        case 'label':
          return <Content i18nKey="messages.category-title-required" />;
        default:
          return <Content i18nKey="messages.category-fields-required" />;
      }
    },
    type: 'error',
  },

  [MEMBER_PROMOTE_WRITER]: {
    content: () => <Content i18nKey="messages.user-promoted-writer" />,
  },
  [MEMBER_DEMOTE_WRITER]: {
    content: () => <Content i18nKey="messages.user-demoted-writer" />,
  },

  [MEMBER_BLOCK]: {
    content: () => <Content i18nKey="messages.user-blocked" />,
  },
  [MEMBER_UNBLOCK]: {
    content: () => <Content i18nKey="messages.user-unblocked" />,
  },

  [CATEGORY_DELETE]: {
    content: () => <Content i18nKey="messages.category-deleted" />,
  },
  [CATEGORY_CREATE]: {
    content: () => <Content i18nKey="messages.category-created" />,
    type: 'success',
  },
  [CATEGORY_SUBSCRIBED]: {
    content: () => <Content i18nKey="messages.category-subscribed" />,
  },
  [CATEGORY_UNSUBSCRIBED]: {
    content: () => <Content i18nKey="messages.category-unsubscribed" />,
  },

  [REQUIRE_PROFILE_FIELDS]: {
    content: (t, props) => {
      const { name, image, cover } = props;
      if (name) {
        return <Content i18nKey="messages.profile-name-required" />;
      }
      if (image) {
        return <Content i18nKey="messages.profile-image-uploading" />;
      }
      if (cover) {
        return <Content i18nKey="messages.profile-cover-uploading" />;
      }
      return <Content i18nKey="messages.profile-unknown-error" />;
    },
    type: 'error',
  },
  [PROFILE_UPDATE]: {
    content: () => <Content i18nKey="messages.profile-updated" />,
  },
  [PROFILE_UPDATE_FAILED]: {
    content: () => <Content i18nKey="messages.profile-update-failed" />,
    type: 'error',
  },
  [SETTINGS_UPDATED]: {
    content: () => <Content i18nKey="messages.settings-saved" />,
    type: 'success',
  },
  [LOGO_SAVED]: {
    content: () => <Content i18nKey="messages.logo-saved" />,
    type: 'success',
  },
  [RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY]: {
    content: () => <Content i18nKey="messages.download-will-begin-shortly" />,
    type: 'info',
  },
  [RCE_FILE_TOO_BIG]: {
    content: () => <Content i18nKey="messages.file-too-big" />,
    type: 'error',
  },
  [RCE_FILE_UPLOAD_LIMITED]: {
    content: () => <Content i18nKey="messages.file-upload-limited" />,
    type: 'error',
  },
  [RCE_FILE_UPLOAD_FAILURE]: {
    content: () => <Content i18nKey="messages.file-upload-failure" />,
    type: 'error',
  },
  [POST_SUBMITTED_FOR_MODERATION]: {
    content: () => <Content i18nKey="messages.post-submitted-for-moderation" />,
    type: 'success',
  },
  [POST_UPDATED_IN_MODERATION]: {
    content: () => <Content i18nKey="messages.post-updated-in-moderation" />,
    type: 'success',
  },
  [TECHNICAL_ISSUE_ERROR]: {
    content: () => <Content i18nKey="messages.technical-issue" />,
    type: 'error',
  },
};
