import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';
import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import { useSelector } from '../runtime-context';
import styles from './change-category-trigger.scss';

const ChangeCategoryTrigger: React.FC<PropsWithChildren<{ title: string }>> = ({
  children,
  title,
}) => {
  const isPostPage = useSelector(getIsPostPageBundle);
  const className = classNames(
    styles.container,
    {
      'blog-card-background-color': !isPostPage,
    },
    'blog-text-color',
    'categories-button',
  );

  return (
    <div className={className}>
      <div className={styles.wrapper}>
        <span data-hook="category-title" className={styles.title}>
          {title}
        </span>
        <ArrowDownIcon className={classNames('blog-icon-fill', styles.icon)} />
        {children}
      </div>
    </div>
  );
};

export default ChangeCategoryTrigger;
