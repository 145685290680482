import { useActions, useSelector } from '@app/external/common/components/runtime-context';
import { getRoute } from '@app/external/common/router/router-selectors';
import { isInPostPage } from '@app/external/common/services/detect-route';

export function useBiClickCallback() {
  const inPostPage = useSelector((s) => isInPostPage(getRoute(s)));
  const actions = useActions();

  const onClickBi = (action: string, postId?: string) => () => {
    if (inPostPage && postId) {
      actions.postPageMoreButtonOptionClicked({ action, postId });
    }
  };

  return onClickBi;
}
