import React from 'react';
import classNames from 'classnames';

import { useTranslation } from '@wix/yoshi-flow-editor';
import Button from '../../../common/components/button';
import { PlusIcon } from '../../../common/components/icons/plus-icon';
import CreatePostHandler from '../../../common/containers/create-post-handler';
import ensureAuth from '../../../common/hoc/ensure-auth';
import styles from './create-post-button.scss';

const ButtonSecure = ensureAuth(Button);
const buttonType = [
  Button.TYPE_FULL_WIDTH_MOBILE,
  Button.TYPE_SMALL_SPACINGS_MOBILE,
  Button.TYPE_LARGE,
];

type Props = {
  categorySlug?: string;
  hidePostfix?: boolean;
  className?: string;
  wrapperClassName?: string;
};

export const CreatePostButton: React.FC<Props> = ({
  categorySlug,
  hidePostfix,
  className,
  wrapperClassName,
}) => {
  const { t } = useTranslation();
  return (
    <CreatePostHandler categorySlug={categorySlug}>
      <ButtonSecure
        type={buttonType}
        className={classNames([styles.wrapper, wrapperClassName])}
        data-hook="create-post"
      >
        <span className={styles.container}>
          <span className={classNames(['create-post-button-label', className])}>
            {t('create-post-button.label')}
          </span>
          {!hidePostfix && <PlusIcon className="blog-button-primary-icon-fill" />}
        </span>
      </ButtonSecure>
    </CreatePostHandler>
  );
};

export default CreatePostButton;
