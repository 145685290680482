import { useStyles } from '@wix/tpa-settings/react';
import {
  getTPASettingsIsCommentsEnabled,
  hasTPACommentsSettings,
  RelatedPostsLabel,
} from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Post/stylesParams';
import { useSelector } from '../components/runtime-context';
import { getIsCommentsEnabled } from '../selectors/app-settings-selectors';
import { getIsPostInPreview } from '../selectors/is-post-in-preview';
import { type UseFeedMetadataSettings } from './use-feed-metadata-settings';

export type UsePostPageSettings = Pick<
  UseFeedMetadataSettings,
  'showComments' | 'relatedPostsLabelKey'
> & {
  showRelatedPostsSeeAllLink: boolean;
};

export const usePostPageSettings = (): UsePostPageSettings => {
  const styles = useStyles();
  const isPostInPreview = useSelector(getIsPostInPreview);
  const isTpaSettings = useSelector(hasTPACommentsSettings);
  const tpaSettingsEnabled = useSelector(getTPASettingsIsCommentsEnabled);
  const commentsEnabled = useSelector(getIsCommentsEnabled);

  const isCommentsEnabled =
    !isPostInPreview && (isTpaSettings ? tpaSettingsEnabled : commentsEnabled);

  return {
    showComments: isCommentsEnabled,
    showRelatedPostsSeeAllLink: styles.get(stylesParams.showRelatedPostsSeeAllLink),
    relatedPostsLabelKey: styles.get(stylesParams.showRelatedPostsLabel)
      ? styles.get(stylesParams.showRelatedPostsLabelType) === RelatedPostsLabel.Recent
        ? 'recent-posts.title'
        : 'related-posts.title'
      : undefined,
  } satisfies UsePostPageSettings;
};
