import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { isArray } from 'lodash';
import { handleEnterKeyUp } from '../../services/accessibility';
import { HorizontalSeparator } from '../separator';
import styles from './action-button.scss';

interface Props {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  readonly?: boolean;
  children?: React.ReactNode;
  biEvent?: () => void;
}

const ActionButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, onClick, children, disabled, readonly, biEvent, ...props }, ref) => {
    const icon = isArray(children) ? children[0] : null;
    const text = isArray(children) ? children[1] : children;
    const rest = isArray(children) ? children.slice(2) : null;
    const isInteractive = !disabled && !readonly;

    const handleClick = isInteractive
      ? () => {
          biEvent?.();
          onClick();
        }
      : () => {};

    return (
      <button
        style={{ textDecoration: 'none' }}
        onClick={handleClick}
        onKeyPress={handleEnterKeyUp(handleClick)}
        className={classNames(
          styles.root,
          className,
          'action-button',
          disabled && styles.disabled,
          readonly && styles.readonly,
        )}
        role="menuitem"
        aria-disabled={isInteractive ? undefined : 'true'}
        aria-label={text as string}
        ref={ref}
        {...props}
      >
        <span
          aria-hidden="true"
          className={classNames(
            styles.actionWrapper,
            'blog-action-button-font',
            'blog-action-button-color',
          )}
        >
          {icon && <span className={classNames(styles.icon, 'blog-icon-fill')}>{icon}</span>}
          <span
            aria-hidden="true"
            className={classNames(
              styles.actionText,
              'blog-text-color',
              'action-button__text',
              disabled && styles.disabled,
            )}
          >
            {text}
          </span>
          {rest}
        </span>
        <span className={styles.separator}>
          <HorizontalSeparator />
        </span>
      </button>
    );
  },
);

export default ActionButton;
