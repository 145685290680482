import React from 'react';
import classNames from 'classnames';
import { flowRight, omit } from 'lodash';
import PropTypes from 'prop-types';
import withDeviceType from '../../hoc/with-device-type';

const AppWrapper = ({ isDesktop, isMobile, className, ...rest }) => {
  const elementProps = omit(rest, 'dispatch');
  return (
    <div
      className={classNames(className, {
        'is-mobile': isMobile,
        'app-mobile': isMobile,
        'is-desktop': isDesktop,
        'app-desktop': isDesktop,
      })}
      {...elementProps}
    />
  );
};

AppWrapper.propTypes = {
  className: PropTypes.string,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default flowRight(withDeviceType)(AppWrapper);
