import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import { showMenuLoginButton as legacyShowMenuLoginButton } from '@app/components/Post/legacyStylesParams';
import stylesParams from '@app/components/Post/stylesParams';
import { useSelector } from '../components/runtime-context';
import {
  getIsBlogMenuCategoryLabelsEnabled,
  getIsBlogMenuEnabled,
  getIsBlogMenuLoginButtonEnabled,
  getIsBlogMenuSearchEnabled,
} from '../selectors/app-settings-selectors';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';

type BlogMenuSettings = {
  showBlogMenu: boolean;
  showCategoryLabels: boolean;
  showSearch: boolean;
  showLoginButton: boolean;
};

const useBlogMenuSettings = (): BlogMenuSettings => {
  const styles = useStyles();
  const { isEditor, isEditorX: isStudio } = useEnvironment();

  const isPostPage = useSelector(getIsPostPageBundle);
  const isMembersAreaInstalled = useSelector(getIsMemberAreaInstalled);

  const showBlogMenuFromStyle = isStudio
    ? styles.get(stylesParams.showMenuCategoryLabels)
    : styles.get(stylesParams.showMenu);
  const showBlogMenuFromStore = useSelector(getIsBlogMenuEnabled);
  const showBlogMenu = isPostPage ? showBlogMenuFromStyle : showBlogMenuFromStore;

  const showCategoryLabelsFromStyle = styles.get(stylesParams.showMenuCategoryLabels);
  const showCategoryLabelsFromStore = useSelector(getIsBlogMenuCategoryLabelsEnabled);
  const showCategoryLabels = isPostPage ? showCategoryLabelsFromStyle : showCategoryLabelsFromStore;

  const showSearchFromStyle = styles.get(stylesParams.showMenuSearch);
  const showSearchFromStore = useSelector(getIsBlogMenuSearchEnabled);
  const showSearch = isPostPage ? showSearchFromStyle : showSearchFromStore;

  const getLoginValueForUndefinedStyle = () => {
    if (isEditor) {
      return undefined; // use style param value
    }

    const isUndefined =
      styles
        .getStylesForAllBreakpoints()
        .some((bp) =>
          legacyShowMenuLoginButton.key ? legacyShowMenuLoginButton.key in bp.booleans : false,
        ) === false;

    return isUndefined ? !isMembersAreaInstalled : undefined;
  };

  const showLoginButtonFromStyle =
    getLoginValueForUndefinedStyle() ?? styles.get(stylesParams.showMenuLoginButton);
  const showLoginButtonFromStore = useSelector(getIsBlogMenuLoginButtonEnabled);
  const showLoginButton = isPostPage ? showLoginButtonFromStyle : showLoginButtonFromStore;

  return {
    showBlogMenu,
    showCategoryLabels,
    showSearch,
    showLoginButton,
  };
};

export default useBlogMenuSettings;
