import React, { useEffect } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { resolveId } from '@wix/communities-blog-client-common';
import { type NormalizedPost } from '@app/external/common/types';
import { PostPageRelatedPostsSectionItem } from './post-page-related-posts-section-item';
import styles from './post-page-related-posts-section.scss';

type Props = {
  postsToShow: NormalizedPost[];
};

const fixSlidesA11y = () => {
  const slides = document.querySelectorAll('.slick-slide');

  slides.forEach((slide) => {
    if (slide.classList.contains('slick-active')) {
      slide.removeAttribute('inert');
    } else {
      slide.setAttribute('inert', 'true');
    }
  });
};

export const PostPageRelatedPostsMobileSlider = ({ postsToShow }: Props) => {
  const { isRTL } = useEnvironment();

  useEffect(() => {
    const timeout = setTimeout(fixSlidesA11y, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div dir="ltr">
      <Slider
        arrows={false}
        className={styles.slider}
        dots={true}
        useCSS={true}
        infinite={true}
        customPaging={Dot}
        centerMode={true}
        centerPadding="30px"
        slidesToShow={1}
        slidesToScroll={1}
        afterChange={fixSlidesA11y}
      >
        {postsToShow.map((relatedPost) => (
          <div
            className={styles.slide}
            key={resolveId(relatedPost)}
            dir={isRTL ? 'rtl' : undefined}
          >
            <PostPageRelatedPostsSectionItem key={relatedPost.id} post={relatedPost} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const Dot = () => (
  <div className={styles.dotContainer}>
    <div className={classNames(styles.dot, 'blog-text-background-color')} />
    <div className={classNames(styles.dot, styles.dotActive, 'blog-button-background-color')} />
  </div>
);
