import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import FluidAvatarImage from '@app/external/common/components/fluid-avatar-image';
import ProfileLink from '@app/external/common/components/link/profile-link';
import TimeAgo from '@app/external/common/components/time-ago';
import TimeToRead from '@app/external/common/components/time-to-read';
import { type NormalizedPost } from '@app/external/common/types';

import { useFullPostLayoutOverrides } from './feed-overrides-context';
import styles from './post-page-post-header-metadata.scss';

type PostPageMetadataSectionProps = {
  post: NormalizedPost;
};

const PostPagePostHeaderMetadata: React.FC<PostPageMetadataSectionProps> = ({ post }) => {
  const { t } = useTranslation();
  const overrides = useFullPostLayoutOverrides();

  return (
    <ul className={styles.root}>
      {overrides.showAuthorName && post.owner?.name && (
        <li className={styles.authorName}>
          {overrides.showAuthorPicture && (
            <div className={styles.authorImageWrapper} style={{ width: 32, height: 32 }}>
              <FluidAvatarImage
                ariaLabel={`${t('aria-label.authors-picture')}: ${post.owner.name}`}
                image={post.owner.image}
                size={32}
                className={styles.authorAvatar}
              />
            </div>
          )}
          <ProfileLink className={styles.authorLink} user={post.owner}>
            <span data-hook="user-name">{post.owner.name}</span>
          </ProfileLink>
        </li>
      )}
      {overrides.showPublishDate && (
        <li className={styles.postPublishDate}>
          <TimeAgo time={post.firstPublishedDate || new Date()} />
        </li>
      )}
      {overrides.showReadingTime && (
        <li className={styles.postReadingTime}>
          <TimeToRead minutes={post.timeToRead} />
        </li>
      )}
    </ul>
  );
};

export default PostPagePostHeaderMetadata;
