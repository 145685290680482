import { type AppState } from '../../types';

const getInstanceValues = (state: AppState) => state.instanceValues;

export function getInstanceValue<K extends keyof AppState['instanceValues']>(
  state: AppState,
  key: K,
  fallback: AppState['instanceValues'][K],
): AppState['instanceValues'][K];
export function getInstanceValue<K extends keyof AppState['instanceValues']>(
  state: AppState,
  key: K,
): AppState['instanceValues'][K] | undefined;
export function getInstanceValue<K extends keyof AppState['instanceValues']>(
  state: AppState,
  key: K,
  fallback?: AppState['instanceValues'][K],
): AppState['instanceValues'][K] | undefined {
  return getInstanceValues(state)?.[key] ?? fallback;
}

export const getIsDemoMode = (state: AppState) => getInstanceValue(state, 'demoMode');
export const getBiToken = (state: AppState) => getInstanceValue(state, 'biToken');
export const getMetaSiteId = (state: AppState) => getInstanceValue(state, 'metaSiteId');
export const getSiteMemberId = (state: AppState) => getInstanceValue(state, 'siteMemberId');
export const getSiteIsTemplate = (state: AppState) => getInstanceValue(state, 'siteIsTemplate');
export const getInstanceId = (state: AppState) => getInstanceValue(state, 'instanceId');
export const getHasUserRole = (state: AppState) => getInstanceValue(state, 'hasUserRole', false);
