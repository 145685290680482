import React, { Suspense } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import Loader from '@app/external/common/components/loader';

const LazyLoadedLiveSitePostEditor = loadable(
  () => /* webpackChunkName: "live-site-post-editor" */ import('./live-site-post-editor'),
);

const PostEditor = () => {
  const { isSSR } = useEnvironment();

  if (isSSR) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <LazyLoadedLiveSitePostEditor />
    </Suspense>
  );
};

export default PostEditor;
