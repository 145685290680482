import React from 'react';
import classNames from 'classnames';
import { take, chain } from 'lodash';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  getCategoryPath,
  getMixedIdsMap,
  getOuterUrl,
  SECTION_BLOG_PAGE,
} from '@wix/communities-blog-client-common';
import { useActions, useSelector } from '@app/external/common/components/runtime-context';
import { getCategoriesMap } from '@app/external/common/store/categories/categories-selectors';
import { getTopology } from '@app/external/common/store/topology/topology-selectors';
import { type NormalizedPost } from '@app/external/common/types';

import { useFullPostLayoutOverrides } from './feed-overrides-context';
import styles from './post-page-post-categories.scss';

type PostPageCategoriesProps = {
  post: NormalizedPost;
  className?: string;
};

const PostPagePostCategories: React.FC<PostPageCategoriesProps> = ({ post, className }) => {
  const DISPLAY_COUNT = 3;
  const categories = useSelector(getCategoriesMap);
  const categoryPath = useSelector(getCategoryPath);
  const { isViewer } = useEnvironment();
  const actions = useActions();
  const topology = useSelector(getTopology);
  const overrides = useFullPostLayoutOverrides();

  let categoriesToDisplay;

  if (post.categories) {
    categoriesToDisplay = take(post.categories, DISPLAY_COUNT);
  } else {
    const categoryIdMap = getMixedIdsMap(Object.values(categories));
    categoriesToDisplay = chain(categoryIdMap)
      .pick(post.categoryIds ?? [])
      .values()
      .take(DISPLAY_COUNT)
      .value();
  }

  if (categoriesToDisplay.length === 0 || overrides.showCategoryLabels === false) {
    return null;
  }

  return (
    <section>
      <ul className={classNames(styles.root, className)}>
        {categoriesToDisplay.map((category) => {
          const href = getOuterUrl(`/${categoryPath}/${category.slug}`, topology.sectionUrl);

          return (
            <li key={category.id}>
              <a
                href={href}
                onClick={(event) => {
                  if (!isViewer) {
                    event.preventDefault();
                    actions.navigateInPreview(href, SECTION_BLOG_PAGE);
                  }
                }}
                className={styles.link}
                data-hook="link"
              >
                {category.menuLabel || category.label}
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default PostPagePostCategories;
