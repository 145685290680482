import { createSelector } from 'reselect';

import { getPostMap } from './post-selectors';

type State = {
  recentPostsIds?: { [postId: string]: string[] };
};

const getRecentPostsIds = (state: State, postId: string) => state.recentPostsIds?.[postId];

export const getRecentPosts = createSelector(
  getPostMap,
  getRecentPostsIds,
  (posts, recentPostsIds) => (recentPostsIds || []).map((id) => posts[id]),
);
