import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { TooltipPortalProvider } from '../../context/tooltip-context';
import AppWrapper from '../app-wrapper';
import { type AppProps } from './app-props';
import styles from './app.scss';

type PropsMobile = {
  mobileHeader?: React.FunctionComponentElement<{ menu?: React.FunctionComponentElement<any> }>;
};

const AppMobile: React.FC<AppProps & PropsMobile> = ({
  main,
  messageRoot,
  mobileHeader,
  modalRoot,
  popoverRoot,
}) => {
  const { isEditor } = useEnvironment();

  useEffect(() => {
    if (!isEditor) {
      document.documentElement.classList.add('enable-scroll');
    }
  }, [isEditor]);

  return (
    <AppWrapper className={classNames(styles.app, 'app-mobile', 'blog-background-color')}>
      <TooltipPortalProvider>
        {messageRoot}
        {modalRoot}
        {popoverRoot}
        <div
          id="content-wrapper"
          className={classNames(styles.scrollable, 'app-mobile__scrollable')}
        >
          {mobileHeader}
          {main}
        </div>
      </TooltipPortalProvider>
    </AppWrapper>
  );
};

export default AppMobile;
