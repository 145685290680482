import React from 'react';
import PropTypes from 'prop-types';
import { getContentText } from '@wix/communities-universal/dist/src/services/editor-content-text';
import { type NormalizedPost } from '../../types';
import styles from './simple-renderer.scss';

type Props = {
  post: NormalizedPost;
  maxLength: number;
};

const Renderer: React.FC<Props> = ({ post, maxLength }) => {
  const text = post.excerpt
    ? post.excerpt.slice(0, maxLength)
    : getContentText(post.content).slice(0, maxLength);

  return (
    <p className={styles.content} data-hook="post-description">
      {text}
    </p>
  );
};

export default Renderer;
