import { get, flowRight } from 'lodash';
import {
  getCardBorderWidthPath,
  isLayoutTextOnImage,
  type LayoutType,
  type Section,
} from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type useCardBorderWidth from '../hooks/use-card-border-width';
import { getAppSettings } from '../selectors/app-settings-base-selectors';
import withHocName from './with-hoc-name';
import withLayoutProps, {
  type WithLayoutOwnProps,
  type WithLayoutProps,
} from './with-layout-props';

const DEFAULT_WIDTH = 1;

export type WithCardBorderWidthOwnProps = WithLayoutOwnProps;

export type WithCardBorderWidth = WithLayoutProps & ReturnType<typeof connector>;

const connector = connect((state, { layoutType, section }: WithLayoutOwnProps) => {
  return {
    borderWidth: isLayoutTextOnImage(layoutType as LayoutType)
      ? 0
      : (get(
          getAppSettings(state),
          getCardBorderWidthPath(layoutType as LayoutType, section as Section),
          DEFAULT_WIDTH,
        ) as number),
  };
});

/** @deprecated use {@link useCardBorderWidth} hook instead */
const withCardBorderWidth = flowRight(
  withLayoutProps(),
  connector,
  withHocName('WithCardBorderWidth'),
);

export default withCardBorderWidth;
