import React from 'react';
import { usePrintContext } from '@app/external/common/components/print-wrapper/print-context';

const WIX_BLOG_HIDE_IN_PRINT = 'wix-blog-hide-in-print';
const WIX_BLOG_PRINT_IN_FULL_WIDTH = 'wix-blog-print-in-full-width';

const printCss = `
  @media print {
    .${WIX_BLOG_HIDE_IN_PRINT} {
      display: none !important;
    }
    .${WIX_BLOG_PRINT_IN_FULL_WIDTH} {
      left: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    #site-root {
      top: 0 !important;
    }
  }`
  .replace(/\s+/g, ' ')
  .trim();

export const usePostPrint = () => {
  const { wrapper } = usePrintContext();

  const print = React.useCallback(() => {
    if (!wrapper) return;

    const body = document.body;
    let currentElement = wrapper.parentElement;

    while (currentElement && currentElement !== body) {
      currentElement.classList.add(WIX_BLOG_PRINT_IN_FULL_WIDTH);
      Array.from(currentElement.parentElement?.children || []).forEach((child) => {
        if (child !== currentElement && !(child instanceof HTMLScriptElement)) {
          child.classList.add(WIX_BLOG_HIDE_IN_PRINT);
        }
      });

      currentElement = currentElement.parentElement;
    }

    if (!document.head.querySelector('#printStyles')) {
      const printStyleElement = document.createElement('style');
      printStyleElement.id = 'printStyles';
      printStyleElement.innerText = printCss;
      document.head.appendChild(printStyleElement);
    }

    window.print();
  }, [wrapper]);

  return print;
};
