import { useMemo } from 'react';
import { Type, Target } from '@wix/ambassador-blog-v3-post/types';
import { useSelector } from '@app/external/common/components/runtime-context';
import { getTopology } from '@app/external/common/store/topology/topology-selectors';
import { type NormalizedPost } from '@app/external/common/types';

export const usePostPaywallContent = (post: NormalizedPost) => {
  const paywallContent = post?.paywallRichContent;

  const pricingPlansUrl = useSelector((state) => state.pricingPlansUrl);
  const { baseUrl } = useSelector(getTopology);
  const subscribeUrl = useMemo(() => {
    try {
      if (baseUrl && typeof pricingPlansUrl === 'string') {
        return new URL(baseUrl + pricingPlansUrl).href;
      }
    } catch {
      return undefined;
    }
  }, [baseUrl, pricingPlansUrl]);

  const paywallContentWithSubscribeUrl = useMemo(() => {
    try {
      if (paywallContent && 'nodes' in paywallContent) {
        const buttonNode = paywallContent.nodes?.[2];

        if (buttonNode?.buttonData && !buttonNode?.buttonData?.link) {
          buttonNode.buttonData.type = Type.LINK;
          const link = {
            url: subscribeUrl,
            anchor: undefined,
            target: Target.SELF,
            rel: {
              nofollow: true,
              sponsored: false,
            },
          };

          buttonNode.buttonData.link = link;
        }

        return paywallContent;
      }
    } catch {
      return paywallContent;
    }
  }, [paywallContent, subscribeUrl]);

  return paywallContentWithSubscribeUrl;
};
