import React from 'react';
import styles from './print-wrapper.scss';
import { PrintContext } from './print-context';

const PrintWrapper: React.FC = ({ children }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  return (
    <PrintContext.Provider value={{ wrapper: wrapperRef.current }}>
      <div className={styles['print-container']} ref={wrapperRef}>
        {children}
      </div>
    </PrintContext.Provider>
  );
};

export default PrintWrapper;
