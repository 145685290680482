import React from 'react';
import { isEmpty } from 'lodash';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { getOuterUrl, resolveId } from '@wix/communities-blog-client-common';
import AnimatedLoader from '@app/external/common/components/animated-loader';
import { useActions, useSelector } from '@app/external/common/components/runtime-context';
import { usePostPageSettings } from '@app/external/common/hooks/use-post-page-settings';
import { getRecentPosts } from '@app/external/common/selectors/recent-posts-selectors';
import { getRelatedPosts } from '@app/external/common/selectors/related-posts-selectors';
import { getPostListCssVars } from '@app/external/common/services/post-list-css-vars';
import {
  getIsRecentPostsLoaded,
  getIsRelatedPostsLoaded,
} from '@app/external/common/store/is-loaded/is-loaded-selectors';
import { getTopology } from '@app/external/common/store/topology/topology-selectors';
import { type NormalizedPost } from '@app/external/common/types';
import { PostPageRelatedPostsMobileSlider } from './post-page-related-posts-mobile-slider';
import { PostPageRelatedPostsSectionItem } from './post-page-related-posts-section-item';
import styles from './post-page-related-posts-section.scss';

type Props = {
  post: NormalizedPost;
};

const PostPageRelatedPostsSection: React.FC<Props> = ({ post }) => {
  const { t } = useTranslation();
  const { relatedPostsLabelKey } = usePostPageSettings();
  const postId = resolveId(post)!;
  const recentPosts = useSelector((state) => getRecentPosts(state, postId));
  const relatedPosts = useSelector((state) => getRelatedPosts(state, postId));
  const isRecentPostsLoaded = useSelector((state) => getIsRecentPostsLoaded(state, postId));
  const isRelatedPostsLoaded = useSelector((state) => getIsRelatedPostsLoaded(state, postId));

  const relatedPostsTitle = t(relatedPostsLabelKey!);
  const hasRelatedPosts = !isEmpty(relatedPosts);
  const isLoading = hasRelatedPosts ? !isRelatedPostsLoaded : !isRecentPostsLoaded;
  const postsToShow = hasRelatedPosts ? relatedPosts : recentPosts;

  const { isViewer, isMobile } = useEnvironment();
  const actions = useActions();
  const topology = useSelector(getTopology);

  const seeAllRoute = getOuterUrl('', topology.sectionUrl);

  if (postsToShow.length === 0) {
    return null;
  }

  return (
    <AnimatedLoader className={styles.rootDisplay} isLoading={isLoading}>
      <section className={styles.root} data-hook="recent-posts" style={getPostListCssVars()}>
        {relatedPostsTitle && (
          <header className={styles.header}>
            <h2 style={{ font: 'inherit' }}>{relatedPostsTitle}</h2>
            <a
              href={seeAllRoute}
              className={styles.seeAllLink}
              onClick={(event) => {
                if (!isViewer) {
                  event.preventDefault();
                  actions.navigateInPreview(seeAllRoute, topology.sectionUrl!);
                }
              }}
            >
              {t('recent-posts.all-posts-link')}
            </a>
          </header>
        )}
        <main>
          {isMobile ? (
            <PostPageRelatedPostsMobileSlider postsToShow={postsToShow} />
          ) : (
            <div className={styles.listWrapper}>
              {postsToShow.map((relatedPost) => (
                <PostPageRelatedPostsSectionItem key={relatedPost.id} post={relatedPost} />
              ))}
            </div>
          )}
        </main>
      </section>
    </AnimatedLoader>
  );
};

export default PostPageRelatedPostsSection;
