import React, { Component } from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import withTranslate from '../../hoc/with-translate';
import IconButton from '../icon-button';
import { NotificationIcon } from '../icons/notification-icon';

class NotificationButton extends Component {
  render() {
    const { className, t, ...otherProps } = this.props;

    return (
      <IconButton
        className={classNames('notification-button', className)}
        icon={NotificationIcon}
        label={t('header.notifications')}
        aria-label={t('header.notifications')}
        {...omit(otherProps, 'dispatch')}
      />
    );
  }
}

NotificationButton.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslate(NotificationButton);
