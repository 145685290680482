import React from 'react';
import { useSelector } from '../../../common/components/runtime-context';
import { getLastPostSlug } from '../../selectors/last-post-slug-selectors';
import NoPostFound from '../no-post-found';
import PostPage from '../post-page';

const LastPostPage = () => {
  const postSlug = useSelector(getLastPostSlug);
  return postSlug ? <PostPage params={{ postSlug }} /> : <NoPostFound />;
};

export default LastPostPage;
