import React, { useEffect } from 'react';
import { useSlotPlaceholder, withSlotsPlaceholders } from '@wix/widget-plugins-ooi';
import { ABOVE_HEADER } from '@app/constants/ooi-slots';
import { FastFormProvider } from '@app/external/common/components/fast-form';
import { getFastFormSubmitAction } from '@app/external/common/components/fast-form/store/fast-form-selectors';
import PermissionsProvider from '@app/external/common/components/permissions-provider';
import ResponsiveListener from '@app/external/common/components/responsive-listener/responsive-listener';
import { withRootProps } from '@app/external/common/components/root-props/with-root-props';
import { useSelector } from '@app/external/common/components/runtime-context';
import { withReduxStore } from '@app/external/common/components/runtime-context/with-redux-store';
import WindowResizeListener from '@app/external/common/components/window-resize-listener';
import { usePostPageShareProviders } from '@app/external/common/hooks/use-post-page-share-providers';
import {
  usePostPushNotifications,
  useBlogNotifications,
} from '@app/external/common/hooks/use-ws-notifications';
import { getIsCreatedWithResponsiveEditor } from '@app/external/common/selectors/app-settings-selectors';
import pinterest from '@app/external/common/services/pinterest';
import {
  getLanguage,
  isSeo as getIsSeo,
  getIsMobile,
  isEditor as getIsEditor,
} from '@app/external/common/store/basic-params/basic-params-selectors';
import Router from '../router';
import styles from './app-root.scss';

function AppRoot() {
  const { language, isMobile, isPluginPinterestEnabled, addResizeListener, submitAction } =
    useSlice();
  const [AboveHeaderSlotsPlaceholder, isAboveHeaderSlotEmpty] = useSlotPlaceholder(ABOVE_HEADER);

  useEffect(() => {
    pinterest.init({ language, isMobile, isPinterestEnabled: isPluginPinterestEnabled });

    return () => {
      pinterest.removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBlogNotifications();
  usePostPushNotifications();

  return (
    <ResponsiveListener dataHook="post-page-root" className={styles.root}>
      <PermissionsProvider>
        <FastFormProvider submitAction={submitAction}>
          <>
            <div className={!isAboveHeaderSlotEmpty ? styles.aboveHeaderSlot : ''}>
              <AboveHeaderSlotsPlaceholder />
            </div>
            <Router />
            {addResizeListener && <WindowResizeListener />}
          </>
        </FastFormProvider>
      </PermissionsProvider>
    </ResponsiveListener>
  );
}

function useSlice() {
  const language = useSelector(getLanguage);
  const isMobile = useSelector(getIsMobile);
  const { isPluginPinterestEnabled } = usePostPageShareProviders();
  const submitAction = useSelector(getFastFormSubmitAction);
  const isSeo = useSelector(getIsSeo);

  const isInClassicEditor = useSelector(
    (state) => getIsEditor(state) && !getIsCreatedWithResponsiveEditor(state),
  );

  return {
    language,
    isSeo,
    isMobile,
    isPluginPinterestEnabled,
    submitAction,
    addResizeListener: isInClassicEditor,
  };
}

export default withSlotsPlaceholders(withRootProps(withReduxStore(AppRoot)));
