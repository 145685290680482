import { permissionsChecker } from '@wix/communities-blog-universal/dist/src/permissions-checker';
import { getPost } from '../selectors/post-selectors';
import type { AppState } from '../types';
import createPermissionFlags from './create-permission-flags';

export default function createPermissionChecker(
  state: Pick<AppState, 'posts'>,
  user: any,
  flags = createPermissionFlags(state),
) {
  const checker = permissionsChecker(flags, user, (resource) => {
    if (resource.postId) {
      return getPost(state, resource.postId);
    }
    return resource;
  });

  checker._key = String(new Date().getTime());

  return checker;
}
