import React from 'react';
import classNames from 'classnames';

type Props = {
  /** @deprecated */
  isSubscribed?: boolean;
} & React.SVGProps<SVGSVGElement>;

export const SubscribeIcon: React.FC<Props> = ({ isSubscribed, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    viewBox="0 0 19 19"
    role="img"
    className={classNames({
      'blog-secondary-fill-color': isSubscribed,
    })}
    {...props}
  >
    <path d="M13.9999934,11.9999874 L12.9999946,11.9999874 L12.9999946,4.00000419 L4,4.00000419 L4,14.9998847 L9.39999675,14.9998847 L10,14.9998847 L10,15.9998629 L9.59999603,15.9998629 L4,15.9998629 L4,15.9998629 C3.44771525,15.9998629 3,15.5521477 3,14.9998629 L3,4.00000419 C3,3.44771944 3.44771525,3.00000419 4,3.00000419 L4,3.00000419 L12.9999934,3.00000419 L12.9999934,3.00000419 C13.5522781,3.00000419 13.9999934,3.44771944 13.9999934,4.00000419 L13.9999934,11.9999874 Z M14,15 L15.8,15 C15.9104569,15 16,15.089543 16,15.2 L16,15.8 C16,15.910457 15.9104569,16 15.8,16 L14,16 L14,17.8 C14,17.910457 13.9104569,18 13.8,18 L13.2,18 C13.0895431,18 13,17.910457 13,17.8 L13,16 L11.2,16 C11.0895431,16 11,15.910457 11,15.8 L11,15.2 C11,15.089543 11.0895431,15 11.2,15 L13,15 L13,13.2 C13,13.089543 13.0895431,13 13.2,13 L13.8,13 C13.9104569,13 14,13.089543 14,13.2 L14,15 Z" />
  </svg>
);
