import React from 'react';
import { getNotificationsLink } from '../../store/ma-navigation/ma-navigation-selectors';
import { useActions, useSelector } from '../runtime-context';
import SiteLink from './site-link';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

declare global {
  interface Window {
    __navigateWithinForumInternally: boolean;
  }
}

const NotificationsLink: React.FC<Props> = ({ ...props }) => {
  const notificationsLink = useSelector(getNotificationsLink) ?? '';
  const actions = useActions();

  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault();
    actions.navigateToNotifications();
  };

  if (typeof window !== 'undefined' && window.__navigateWithinForumInternally) {
    props.onClick = handleClick;
  }

  return <SiteLink to={notificationsLink} {...props} />;
};

export default NotificationsLink;
