import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { flowRight, get } from 'lodash';
import { connect, useActions, useSelector } from '../../../components/runtime-context';
import { MESSAGE_ERROR, MESSAGE_INFO, MESSAGE_IMPORTANT } from '../messages-types';
import { getMessage } from '../store/message-selectors';
import styles from './message-root.scss';

const getA11yPropForMessageType = (level: string) => {
  switch (level) {
    case MESSAGE_ERROR:
      return { role: 'alert' };
    case MESSAGE_INFO:
      return { 'aria-live': 'polite' as const };
    case MESSAGE_IMPORTANT:
      return { 'aria-live': 'assertive' as const };
    default:
      return {};
  }
};

const CLOSE_TIMEOUT = 3000;

type Props = {
  MessageComponent: React.ComponentType<any>;
  closeTimeout?: number;
  messages: Record<string, any>;
  className?: string;
  hideMessage: () => void;
  messageType?: string;
  messageProps?: any;
};

const MessageRoot: React.FC<Props> = ({
  MessageComponent,
  closeTimeout = CLOSE_TIMEOUT,
  messages,
  className,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { type: messageType, props: messageProps } = useSelector(getMessage);

  const { hideMessage } = useActions();

  useEffect(() => {
    if (messageType) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(hideMessage, closeTimeout);
    }

    return () => {
      clearTimeout(timeoutRef.current);
      hideMessage();
    };
  }, [messageType, closeTimeout, hideMessage]);

  const message = messages[messageType || ''];
  const a11yProps = getA11yPropForMessageType(get(message, 'type'));

  return (
    <div
      className={classNames(className || styles.container, 'message')}
      data-hook="message"
      {...a11yProps}
    >
      {message ? (
        <MessageComponent message={message} messageProps={messageProps} onClose={hideMessage} />
      ) : null}
    </div>
  );
};

export function createMessageRoot({
  MessageComponent,
  closeTimeout = CLOSE_TIMEOUT,
  messages,
  className,
}: Omit<Props, 'hideMessage' | 'messageType' | 'messageProps'>) {
  return (props: any) => (
    <MessageRoot
      {...props}
      MessageComponent={MessageComponent}
      closeTimeout={closeTimeout}
      messages={messages}
      className={className}
    />
  );
}
