import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { resolveId } from '@wix/communities-blog-client-common';
import MoreIcon from '@app/external/common/components/icons/more-icon';
import { PinIcon } from '@app/external/common/components/icons/pin-icon';
import Loader from '@app/external/common/components/loader';
import { useGetContainerPosition } from '@app/external/common/components/more-button/more-button-hook';
import PopoverRoot from '@app/external/common/components/popovers/popover-root';
import { usePostActions } from '@app/external/common/components/post-actions/post-actions';
import { useActions, useSelector } from '@app/external/common/components/runtime-context';
import { isSite as getIsSite } from '@app/external/common/store/basic-params/basic-params-selectors';
import { type NormalizedPost } from '@app/external/common/types';
import { useFullPostLayoutOverrides } from './feed-overrides-context';
import styles from './post-page-post-header-more-actions.scss';

const FocusTrap = React.lazy(() => import('focus-trap-react'));

type PostPagePostHeaderMoreActionsProps = {
  post: NormalizedPost;
};

const PostPagePostHeaderMoreActions: React.FC<PostPagePostHeaderMoreActionsProps> = ({ post }) => {
  const overrides = useFullPostLayoutOverrides();

  return (
    <div className={styles.root}>
      {post.isPinned ? (
        <span className={styles.iconBox}>
          <PinIcon />
        </span>
      ) : undefined}

      {overrides.showMoreOptionsMenu && <PostPageMoreActionsMenu post={post} />}
    </div>
  );
};

type PostPageMoreActionsMenuProps = {
  post: NormalizedPost;
};

const PostPageMoreActionsMenu: React.FC<PostPageMoreActionsMenuProps> = ({ post }) => {
  const postId = resolveId(post);
  const htmlId = `more-button-${postId}` as const;
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  const actions = useActions();

  const itemsToShow = usePostActions(post);
  const items = [
    itemsToShow.subscribe,
    itemsToShow.edit,
    itemsToShow.share,
    itemsToShow.print,
    itemsToShow.pin,
    itemsToShow.toggleComments,
    itemsToShow.delete,
    itemsToShow.duplicate,
  ].filter((f): f is NonNullable<typeof f> => f !== undefined);

  const showComponent = () => {
    actions.postPageMoreButtonClicked({ postId });
    document.addEventListener('click', hideComponent, { once: true });
    setIsVisible(true);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isVisible) {
      hideComponent();
    } else {
      showComponent();
    }
  };

  const hideComponent = useCallback((): void => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        hideComponent();
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [hideComponent]);

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <button
        onClick={handleClick}
        className={styles.menuButton}
        ref={buttonRef}
        type="button"
        aria-pressed={isVisible}
        aria-label={t('more-button.more-actions')}
        id={htmlId}
        data-hook="more-button"
      >
        <MoreIcon />
      </button>
      {isVisible
        ? ReactDOM.createPortal(
            <PostPageMoreActionsMenuItems items={items} buttonRef={buttonRef} />,
            PopoverRoot.getPopoverRootElement()!,
          )
        : null}
    </>
  );
};

type DefinedPostActionItem = NonNullable<
  ReturnType<typeof usePostActions>[keyof ReturnType<typeof usePostActions>]
>;

type PostPageMoreActionsMenuItemsProps = {
  items: DefinedPostActionItem[];
  buttonRef: React.RefObject<HTMLButtonElement>;
};

const PostPageMoreActionsMenuItems: React.FC<PostPageMoreActionsMenuItemsProps> = ({
  items,
  buttonRef,
}) => {
  const actions = useActions();
  const isSite = useSelector(getIsSite);
  const actionsContainerRef = useRef<HTMLDivElement | null>(null);
  const componentId = useSelector((_, host) => host.id);
  const { calculateActionsContainerPosition, checkIfFits, checkIfFitsInEditor } =
    useGetContainerPosition({
      actionsContainerRef,
      containerRef: buttonRef,
      componentId,
    });

  useEffect(() => {
    actions.actionsOpened({ type: 'post' });

    return () => {
      actions.actionsClosed({ type: 'post' });
    };
  });

  useEffect(() => {
    if (isSite) {
      checkIfFits();
    } else {
      checkIfFitsInEditor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={actionsContainerRef}
      className={styles.popoverRoot}
      style={calculateActionsContainerPosition()}
      role="menu"
      data-hook="actions"
      aria-labelledby={buttonRef.current?.id}
    >
      <Suspense fallback={<Loader />}>
        <FocusTrap
          focusTrapOptions={{
            allowOutsideClick: true,
            preventScroll: false,
            escapeDeactivates: true,
            fallbackFocus: buttonRef.current!,
          }}
        >
          <div>
            {items.map((item) => (
              <button
                key={item.buttonProps['data-hook']}
                role="menuitem"
                className={styles.listItem}
                aria-label={item.text}
                {...item.buttonProps}
              >
                <span className={styles.listItemIconWrapper}>
                  <item.icon />
                </span>

                {item.text}
              </button>
            ))}
          </div>
        </FocusTrap>
      </Suspense>
    </div>
  );
};

export default PostPagePostHeaderMoreActions;
